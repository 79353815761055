import TeacherLayout from "@/views/teacher/layout/layout.vue";
import TeacherDashboard from "@/views/teacher/dashboard.vue";
// import InnerLayout from "@/components/layout.vue";
import Courses from "@/views/teacher/courses/index.vue";
// import CreateClass from "@/views/teacher/class/create.vue";
import TeacherCourses from "@/views/teacher/courses/index.vue";
import Student from "@/views/teacher/student/index.vue";
import Chat from "@/views/student/chat/chat.vue";
import Schedule from "@/views/teacher/schedule/index.vue";
import Settings from "@/views/teacher/settings/index.vue";
import Profile from "@/views/teacher/profile/index.vue";
import CreateCourse from "@/views/teacher/courses/create.vue";
import EditCourse from "@/views/teacher/courses/edit.vue";
const teacherRoutes = [
  {
    path: "/teacher",
    component: TeacherLayout,
    children: [
      {
        path: "dashboard",
        name: "teacher-dashboard",
        component: TeacherDashboard,
        meta: {
          name: "Teacher Dashboard",
        },
      },
      {
        path: "courses",
        name: "teacher-courses",
        component: Courses,
        meta: {
          name: "courses",
        },
      },
      {
        path: "create-course",
        name: "create-course",
        component: CreateCourse,
        meta: {
          name: "Create Course",
        },
      },
      {
        path: "edit-course/:id",
        name: "edit-course",
        component: EditCourse,
        meta: {
          name: "Edit Course",
        },
      },
      {
        path: "teacher-courses",
        name: "courses",
        component: TeacherCourses,
        meta: {
          name: "courses",
        },
      },
      {
        path: "student",
        name: "teacher-student",
        component: Student,
        meta: {
          name: "student",
        },
      },
      {
        path: "chat",
        name: "teacher-chat",
        component: Chat,
        meta: {
          name: "chats",
        },
      },
      {
        path: "schedule",
        name: "teacher-schedule",
        component: Schedule,
        meta: {
          name: "schedule",
        },
      },
      // {
      //   path: "live",
      //   name: "teacher-live",
      //   component: LiveClass,
      //   meta: {
      //     name: "live class",
      //   },
      //   children: [
      //     // {
      //     //   path: "/",
      //     //   name: "create-class",
      //     //   component: CreateClass,
      //     //   meta: {
      //     //     name: "live class",
      //     //   },
      //     // },
      //   ],
      // },
      {
        path: "profile",
        name: "teacher-profile",
        component: Profile,
        meta: {
          name: "my profile",
        },
      },
      {
        path: "settings",
        name: "teacher-settings",
        component: Settings,
        meta: {
          name: "settings",
        },
      },
    ],
  },
];

export default teacherRoutes;
