<template>
  <div class="w-full pt-6 sm:pr-5 pr-2 lg:pl-0 sm:pl-5 pl-2" v-if="loaded">
    <h1 style="font-size: 22px">{{ module.title }}</h1>

    <h3 style="font-size: 18px" class="my-4">Videos</h3>
    <div
      class="bg-teal-100 mt-3 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
      role="alert"
      v-if="module.videos.length < 1"
    >
      <span class="block sm:inline">No video found</span>
    </div>

    <div
      class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 pb-10"
      v-else
    >
      <!--------Grid 1--------------->
      <div v-for="(item, index) in module.videos" :key="index">
        <div>
          <div class="m-video bg-white w-[100%] rounded-sm pl-4 pt-5 pb-1 pr-5">
            <vue-plyr :options="options">
              <video controls crossorigin playsinline>
                <source size="576" :src="item.url" type="video/mp4" />
              </video>
            </vue-plyr>
          </div>
        </div>
      </div>
    </div>

    <h3 style="font-size: 18px" class="my-4">Documents</h3>
    <div
      class="bg-teal-100 mt-3 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
      role="alert"
      v-if="module.documents.length < 1"
    >
      <span class="block sm:inline">No document found</span>
    </div>

    <div
      class="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 pb-10"
      v-else
    >
      <!--------Grid 1--------------->
      <div v-for="(item, index) in module.documents" :key="index">
        <div style="height: 300px">
          <iframe
            :src="`http://docs.google.com/gview?url=${item.url}&embedded=true`"
            style="width: 100%"
            frameborder="0"
          ></iframe>
          <!-- <div class="m-video bg-white w-[100%] rounded-sm pl-4 pt-5 pb-1 pr-5">
            <a :href="item.url">
              <embed :src="item.url" width="100px" height="50px" />
            </a>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /* eslint-disable */
  import { initFlowbite } from "flowbite";
  export default {
    name: "Module",
    data() {
      return {
        module: [],
        loaded: false,
        options: { quality: { default: "576p" } },
      };
    },
    methods: {
      getModule() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("get", `student/get-module/${this.$route.params.id}`)
          .then((resp) => {
            this.$store.commit("setLoader", false);
            console.log(resp);
            this.module = resp.data;
            this.loaded = true;
          })
          .catch((err) => {
            this.$store.commit("setLoader", false);
            this.$store.dispatch("handleError", err);
          });
      },
    },
    created() {
      initFlowbite();
      this.getModule();
    },
  };
</script>

<style scoped>
  .m-video {
    height: 190px;
  }
  iframe {
    height: 300px;
  }
</style>
