<template>
  <div
    class="fixed z-40 right-[50%] translate-x-[50%] top-5 cursor-pointer"
    style="z-index: 999"
  >
    <div
      id="alert-1"
      v-if="notification.type == 1"
      class="flex p-4 mb-4 bg-green-500 rounded-lg shadow"
      @click="close"
      role="alert"
    >
      <svg
        class="flex-shrink-0 w-5 h-5 text-white"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div
        class="mx-3 text-sm font-medium text-white"
        v-html="notification.message"
      ></div>
    </div>

    <div
      id="alert-1"
      v-if="notification.type == 2"
      class="flex p-4 mb-4 bg-red-600 rounded-lg shadow"
      @click="close"
      role="alert"
    >
      <svg
        class="flex-shrink-0 w-5 h-5 text-red"
        fill="white"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clip-rule="evenodd"
        ></path>
      </svg>
      <div
        class="mx-3 text-sm font-medium text-white"
        v-html="notification.message"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "notification-component",
  computed: {
    ...mapState({
      notification: (state) => state.notification,
    }),
  },
  methods: {
    close() {
      this.$store.commit("setNotification", { type: 0, message: "" });
    },
  },
};
</script>

<style scoped>
#alert-1 {
  opacity: 0;
  animation: fade-in 0.5s ease-out forwards;
  /* 3s duration, ease-out timing function */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0.5;
  }

  100% {
    opacity: 1;
  }
}
</style>
