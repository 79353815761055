<template>
  <div
    class="lg:flex block w-full pt-8 md:pr-5 pr-2 lg:pl-0 md:pl-5 pl-2 lg:mb-0 mb-6"
  >
    <div class="bg-white w-full rounded-[26px] mr-6 lg:mb-0 mb-5">
      <div class="bg-white w-full rounded-[26px] mr-6 lg:mb-0 mb-5">
        <!-- :min-date="new Date()" -->
        <vue-cal
          class="vuecal--blue-theme"
          active-view="month"
          :disable-views="['week', 'years', 'year']"
          :events="schedules"
          events-on-month-view="short"
        ></vue-cal>
      </div>
    </div>
    <div
      class="lg:w-[560px] w-full bg-white h-full rounded-[26px] sm:px-6 px-4 mt-7 py-4"
    >
      <div class="">
        <p class="text-[rgba(4,4,4,1)] text-lg font-[MontMedium] mb-4 text">
          Reminders
        </p>
        <a
          class="flex-between-center mb-6"
          v-for="(schedule, index) in schedules"
          :key="index"
          :href="schedule.url"
          target="_blank"
        >
          <div class="flex-all-start" v-if="index < 10">
            <div>
              <p
                class="text-[rgba(4,4,4,1)] text-sm font-[MontMedium] mb-2 sche-title"
              >
                {{ schedule.title }}
              </p>
            </div>
          </div>
          <div class="cursor-pointer text-xs">
            {{ formatDateToLocalDate(schedule.start) }}
          </div>
          <div>
            <a
              :href="schedule.url"
              target="_blank"
              class="btn btn-primary"
              style="font-size: 13px"
              >Join Call</a
            >
          </div>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
  import { initFlowbite } from "flowbite";
  import VueCal from "vue-cal";
  import "vue-cal/dist/vuecal.css";
  export default {
    name: "student-schedule",
    components: {
      VueCal,
    },
    mounted() {
      initFlowbite();
    },
    data() {
      return {
        events: [],
        event: {
          start_date: "",
          end_date: "",
          content: "rjrjr",
          title: "Intro class",
          course: "",
        },
        courses: [],
        schedules: [],
      };
    },
    methods: {
      getSchedules() {
        this.$store
          .dispatch("get", "student/upcoming-schedules")
          .then((resp) => {
            this.schedules = resp.data;
            this.schedules.forEach((item) => {
              item.start = item.start.replace("T", " ");
              item.end = item.end.replace("T", " ");
            });
            // console.log(this.schedules);
          })

          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
    },
    created() {
      this.getSchedules();
    },
  };
</script>

<style scoped>
  .vuecal {
    height: 33em;
  }
  @media (min-width: 768px) {
    .sche-title {
      max-width: 120px;
      width: 120px;
    }
  }
</style>
