export default {
  methods: {
    formatDateTime(value) {
      var hold = new Date(value).toDateString().split(" ");
      return `${hold[2]} ${hold[1]} '${hold[3][2]}${hold[3][3]}`;
    },
    formatDateTimeSecond(value) {
      let m = new Date(value);
      return m.toUTCString().replace("GMT", "");
    },
    formatDateToLocalDate(value) {
      return new Date(value).toLocaleDateString();
    },
  },
};
