<template>
  <div>
    <div class="top-0 left-0 right-0 h-[120px] flex z-20 fixed">
      <div class="min-w-[290px] lg:block hidden"></div>
      <div class="w-full mr-2 bg-transparent h-[80px] ml-auto px-3">
        <div
          class="flex justify-between items-center lg:py-4 py-3 px-2 rounded-lg"
          :class="navbarBackgroundColor"
        >
          <div class="flex justify-start items-center">
            <span class="lg:hidden block md:mr-8 mr-6" @click="open">
              <i
                class="fa-solid fa-bars text-2xl text-[rgba(42,43,97,1)] cursor-pointer"
              ></i>
            </span>
            <span>
              <p
                class="font-[MontBold] text-[rgba(4,4,4,1)] lg:text-lg text-base mr-12 md:block hidden capitalize"
              >
                {{ $route.meta.name }}
              </p>
            </span>
            <div
              class="bg-white w-[47px] h-[47px] rounded-full flex justify-center items-center"
            >
              <svg
                width="21"
                height="21"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7669 20.7552C16.7311 20.7552 20.7554 16.7309 20.7554 11.7666C20.7554 6.80239 16.7311 2.77808 11.7669 2.77808C6.80264 2.77808 2.77832 6.80239 2.77832 11.7666C2.77832 16.7309 6.80264 20.7552 11.7669 20.7552Z"
                  stroke="#2A2B61"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  opacity="0.4"
                  d="M18.0186 18.4851L21.5426 22"
                  stroke="#2A2B61"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-end items-center">
            <div>
              <Activities></Activities>
            </div>

            <div>
              <button
                id="dropdownDefaultButton"
                data-dropdown-toggle="dropdownProfile"
                class="text-white font-[MontMedium] focus:ring-0 focus:outline-none focus:ring-transparent font-medium text-sm px-5 py-2.5 text-center inline-flex items-center"
                type="button"
              >
                <p
                  class="font-[MontMedium] mr-1 whitespace-nowrap text-[rgba(4,4,4,1)] text-base"
                >
                  {{ user.username }}
                </p>
                <span>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0004 7H7.00036C6.82211 7.00064 6.64725 7.04882 6.49383 7.13957C6.34041 7.23031 6.21397 7.36035 6.12756 7.51625C6.04115 7.67216 5.99789 7.8483 6.00225 8.0265C6.00661 8.20469 6.05843 8.3785 6.15236 8.53L11.1524 16.53C11.2422 16.6738 11.3672 16.7924 11.5156 16.8746C11.6639 16.9569 11.8308 17 12.0004 17C12.17 17 12.3368 16.9569 12.4851 16.8746C12.6335 16.7924 12.7585 16.6738 12.8484 16.53L17.8484 8.53C17.943 8.37863 17.9953 8.20469 18 8.02625C18.0047 7.8478 17.9615 7.67136 17.875 7.51523C17.7885 7.3591 17.6617 7.22898 17.5079 7.13838C17.3541 7.04778 17.1789 7 17.0004 7Z"
                      fill="#040404"
                    />
                  </svg>
                </span>
              </button>
              <!-- Dropdown menu -->
              <div
                id="dropdownProfile"
                class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
              >
                <ul
                  class="py-2 text-sm text-gray-700 dark:text-gray-200"
                  aria-labelledby="dropdownDefaultButton"
                >
                  <li>
                    <router-link
                      :to="{ name: 'teacher-profile' }"
                      class="block px-4 py-2 hover:bg-gray-100"
                      >Profile</router-link
                    >
                  </li>
                  <li>
                    <a href="/" class="block px-4 py-2 hover:bg-gray-100"
                      >Sign out</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import Activities from "@/components/activities.vue";
  export default {
    name: "student-navbar",
    components: { Activities },
    computed: {
      ...mapState({
        user: (state) => state.user,
      }),
    },
    data() {
      return {
        element: document.querySelector("#sidebar"),
        layer: document.querySelector("#sidebar-layer"),
        navbarBackgroundColor: "bg-red-400",
        listColor: "",
        scrollTriggerOffset: 50,
      };
    },
    methods: {
      open() {
        this.element.classList.add("open-sidebar");
        this.element.classList.remove("close-sidebar");
        this.layer.classList.remove("hidden");
        this.layer.classList.add("block");
      },
      handleScroll() {
        if (window.scrollY > this.scrollTriggerOffset) {
          this.navbarBackgroundColor = "bg-white shadow";
          this.listColor = "text-black";
        } else {
          this.navbarBackgroundColor = "transparent";
          this.listColor = "";
        }
      },
    },
    mounted() {
      this.handleScroll();
      window.addEventListener("scroll", this.handleScroll);
      this.element = document.querySelector("#sidebar");
      this.layer = document.querySelector("#sidebar-layer");
    },
    onUnmounted() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  };
</script>
<style scoped></style>
