<template>
  <div
    class="bg-white rounded-[25px] p-5 mt-5 h-max min-h-[60vh]"
    v-if="loaded"
  >
    <div class="h-full">
      <div>
        <h4>Edit Course</h4>
        <div class="mt-6">
          <div class="w-full flex-all-center py-8">
            <div class="profile-img w-[80px] h-[80px] relative">
              <img :src="course.image" alt="" />

              <label
                for="profileImage"
                class="absolute bottom-0 right-[-10px] border w-[30px] h-[30px] rounded-full profile-camera flex-all-center cursor-pointer"
              >
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 17 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8.66093 5.99341C7.2156 5.99341 5.99426 7.21474 5.99426 8.66007C5.99426 10.1054 7.2156 11.3267 8.66093 11.3267C10.1063 11.3267 11.3276 10.1054 11.3276 8.66007C11.3276 7.21474 10.1063 5.99341 8.66093 5.99341ZM8.66093 9.99341C7.95093 9.99341 7.3276 9.37007 7.3276 8.66007C7.3276 7.95007 7.95093 7.32674 8.66093 7.32674C9.37093 7.32674 9.99426 7.95007 9.99426 8.66007C9.99426 9.37007 9.37093 9.99341 8.66093 9.99341Z"
                    fill="white"
                  />
                  <path
                    d="M13.9943 3.99341H12.2703L10.4656 2.18874C10.4038 2.12671 10.3303 2.07752 10.2494 2.044C10.1685 2.01047 10.0818 1.99328 9.99426 1.99341H7.3276C7.24003 1.99328 7.15331 2.01047 7.07242 2.044C6.99153 2.07752 6.91807 2.12671 6.85626 2.18874L5.0516 3.99341H3.3276C2.59226 3.99341 1.99426 4.59141 1.99426 5.32674V12.6601C1.99426 13.3954 2.59226 13.9934 3.3276 13.9934H13.9943C14.7296 13.9934 15.3276 13.3954 15.3276 12.6601V5.32674C15.3276 4.59141 14.7296 3.99341 13.9943 3.99341ZM3.3276 12.6601V5.32674H5.3276C5.50493 5.32674 5.67426 5.25674 5.79893 5.13141L7.6036 3.32674H9.71826L11.5229 5.13141C11.5847 5.19344 11.6582 5.24263 11.7391 5.27615C11.82 5.30968 11.9067 5.32687 11.9943 5.32674H13.9943L13.9956 12.6601H3.3276Z"
                    fill="white"
                  />
                </svg>
              </label>
              <input type="file" id="profileImage" style="visibility: hidden" />
            </div>
          </div>
          <div class="w-full">
            <div class="sm:flex justify-between w-full">
              <div class="mb-6 w-full relative">
                <label
                  for="full-name"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Course Title</label
                >
                <input
                  type="text"
                  id="full-name"
                  class="profile_input"
                  v-model="course.title"
                  required
                />
              </div>
            </div>
            <div class="sm:flex justify-between w-full">
              <div class="mb-6 w-full relative">
                <label
                  for="full-name"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Slack Channel Url</label
                >
                <input
                  type="text"
                  id="slackUrl"
                  class="profile_input"
                  v-model="course.slack_url"
                  required
                />
              </div>
            </div>

            <div class="mb-6 w-full relative">
              <label
                for="full-name"
                class="block mb-4 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                >Description</label
              >

              <textarea
                id="full-name"
                class="profile_input pt-[35px!important] min-h-[100px] placeholder:text-xs"
                v-model="course.description"
                required
              ></textarea>
            </div>
            <div class="w-full text-left">
              <button
                class="green-btn mb-8 max-w-[170px]"
                @click.prevent="updateCourse()"
              >
                Save Changes
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="d-flex justify-content-between align-items-center">
            <p>Modules</p>
            <div class="d-flex">
              <button
                class="btn btn-primary"
                data-modal-target="addModal"
                data-modal-toggle="addModal"
              >
                Add Module
              </button>
            </div>
          </div>

          <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
            <table
              class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            >
              <thead
                class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
              >
                <tr>
                  <th scope="col" class="px-6 py-3">S/N</th>
                  <th scope="col" class="px-6 py-3">Module Topic</th>
                  <th scope="col" class="px-6 py-3">Videos</th>
                  <th scope="col" class="px-6 py-3">Documents</th>
                  <th scope="col" class="px-6 py-3">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                  v-for="(item, index) in course.modules"
                  :key="index"
                >
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ ++index }}
                  </td>
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ item.title }}
                  </td>
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ item.videos.length }}
                  </td>
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {{ item.documents.length }}
                  </td>
                  <td
                    scope="row"
                    class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <button
                      class="action me-4"
                      @click.prevent="removeModule(index, item.uuid)"
                    >
                      <svg
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Layer_2" data-name="Layer 2">
                          <path
                            d="m22 6.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"
                            fill="#000000"
                            style="fill: rgb(243, 18, 18)"
                          ></path>
                          <path
                            d="m17 6.75h-10a.751.751 0 0 1 -.728-.932l.811-3.242a1.748 1.748 0 0 1 1.7-1.326h6.438a1.748 1.748 0 0 1 1.7 1.325l.811 3.243a.751.751 0 0 1 -.732.932zm-9.04-1.5h8.079l-.577-2.311a.25.25 0 0 0 -.243-.189h-6.438a.25.25 0 0 0 -.243.189z"
                            fill="#000000"
                            style="fill: rgb(243, 18, 18)"
                          ></path>
                          <path
                            d="m17 22.75h-10a3.754 3.754 0 0 1 -3.75-3.75v-13a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v13a3.755 3.755 0 0 1 -3.75 3.75zm-12.25-16v12.25a2.252 2.252 0 0 0 2.25 2.25h10a2.253 2.253 0 0 0 2.25-2.25v-12.25z"
                            fill="#000000"
                            style="fill: rgb(243, 18, 18)"
                          ></path>
                          <path
                            d="m9 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                            fill="#000000"
                            style="fill: rgb(243, 18, 18)"
                          ></path>
                          <path
                            d="m15 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                            fill="#000000"
                            style="fill: rgb(243, 18, 18)"
                          ></path>
                        </g>
                      </svg>
                    </button>
                    <button
                      class="action"
                      data-modal-target="editModal"
                      data-modal-toggle="editModal"
                      @click="setModule(item)"
                    >
                      <svg
                        version="1.1"
                        id="Layer_1"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlns:xlink="http://www.w3.org/1999/xlink"
                        x="0px"
                        y="0px"
                        viewBox="0 0 488.877 488.877"
                        style="enable-background: new 0 0 488.877 488.877"
                        xml:space="preserve"
                      >
                        <g>
                          <g>
                            <path
                              d="M488.3,100.567c-0.7-1.6-1.1-3.6-1.5-5.6c-1.1-5.3-2.3-11.2-5-17.2c-15-34.2-39.8-58.5-73.7-72.2c-7.3-3-14.6-3.9-21-4.6 l-1.6-0.2c-7.4-0.9-18.4,0.1-28.7,10.6c-43.7,43.9-88.2,88.4-131.3,131.5l-31.6,31.5c-7.1,7.1-10,10-11.5,11.5s-1.5,1.5-2.7,2.7 c-1.3,1.3-4,4.1-11.8,12c-7,7.1-6.9,18.5,0.2,25.5c7.1,7,18.5,6.9,25.5-0.2c7.9-8,10.6-10.8,11.9-12.1c1.1-1.1,1.1-1.1,2.4-2.4 c1.5-1.5,4.4-4.4,11.5-11.5l31.6-31.6c43.1-43.1,87.6-87.6,131.4-131.6l0.1-0.1h0.3c4.6,0.6,9,1.1,11.9,2.3 c25.3,10.2,43,27.7,54.3,53.3c1.1,2.6,1.9,6.2,2.7,10.1c0.2,1,0.4,1.9,0.6,2.9c-0.1,0.1-0.2,0.2-0.4,0.4 c-72,71.8-146.9,146.7-229.2,229.1c-0.4,0.4-0.4,0.4-1.1,0.6c-27.6,5.8-55.6,11.7-82.6,17.4l-4.3,0.9l0.8-3.7 c5.8-27.5,11.8-55.9,17.9-83.8c2.1-9.7-4-19.3-13.7-21.4c-9.7-2.1-19.3,4-21.4,13.7c-6.1,28-12.1,56.5-17.9,84.1l-3.3,15.6 c-2,9.6,0.7,19.3,7.3,25.9c5.2,5.2,12.2,8,19.7,8c2.1,0,4.2-0.2,6.3-0.7l16.3-3.4c27.1-5.7,55-11.6,82.5-17.4 c7.6-1.6,13.9-5,19.2-10.4c82.2-82.4,157.2-157.3,229.1-229.1c6.7-6.7,10.4-14.7,11.3-24.3 C489,104.667,488.8,102.567,488.3,100.567z"
                            />
                          </g>
                        </g>
                        <g>
                          <g>
                            <path
                              d="M387.7,263.767c-9.9,0-18,8.1-18,18v162.7c0,4.4-3.5,7.9-7.9,7.9H43.9c-4.4,0-7.9-3.5-7.9-7.9v-317.9 c0-4.4,3.5-7.9,7.9-7.9h169.6c9.9,0,18-8.1,18-18c0-9.9-8.1-18-18-18H43.9c-24.2,0-43.9,19.7-43.9,43.9v317.9 c0,24.2,19.7,43.9,43.9,43.9h317.9c24.2,0,43.9-19.7,43.9-43.9v-162.7C405.7,271.867,397.6,263.767,387.7,263.767z"
                            />
                          </g>
                        </g>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- Main modal -->
      <div
        id="editModal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative w-full max-w-2xl max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div
              class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
            >
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Edit Module
              </h3>
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="editModal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>

            <!-- Modal content -->
            <div class="mb-4 border-b">
              <ul
                class="flex flex-wrap -mb-px text-sm font-medium text-center"
                id="default-tab"
                data-tabs-toggle="#default-tab-content"
                role="tablist"
              >
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block p-4 border-b-2 rounded-t-lg"
                    id="profile-tab"
                    data-tabs-target="#profile"
                    type="button"
                    role="tab"
                    aria-controls="profile"
                    aria-selected="false"
                  >
                    Update
                  </button>
                </li>
                <li class="mr-2" role="presentation">
                  <button
                    class="inline-block p-4 border-b-2 rounded-t-lg hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300"
                    id="dashboard-tab"
                    data-tabs-target="#dashboard"
                    type="button"
                    role="tab"
                    aria-controls="dashboard"
                    aria-selected="false"
                  >
                    Add Video/Document
                  </button>
                </li>
              </ul>
            </div>
            <div id="default-tab-content">
              <div
                class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <!-- Modal body -->
                <div class="p-3">
                  <form action="">
                    <div
                      class="form-group shadow border border-gray-200 rounded-lg p-2 mb-4"
                    >
                      <div class="w-full relative">
                        <label
                          for="full-name"
                          class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                          >Module Title</label
                        >
                        <input
                          type="text"
                          id="full-name"
                          class="profile_input"
                          v-model="editModule.title"
                          required
                        />

                        <button
                          class="btn btn-primary mt-2"
                          @click.prevent="updateModule()"
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </form>
                  <div
                    class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 editVideo"
                  >
                    <span>Videos</span>
                    <table
                      class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                    >
                      <thead
                        class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                      >
                        <tr>
                          <th scope="col" class="px-6 py-3">S/N</th>
                          <th scope="col" class="px-6 py-3">Videos</th>
                          <th scope="col" class="px-6 py-3">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                          v-for="(item, index) in editModule.videos"
                          :key="index"
                        >
                          <td
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            {{ ++index }}
                          </td>
                          <td
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <vue-plyr :options="options">
                              <video controls crossorigin playsinline>
                                <source
                                  size="200"
                                  :src="item.url"
                                  type="video/mp4"
                                />
                              </video>
                            </vue-plyr>
                          </td>
                          <td
                            scope="row"
                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                          >
                            <button
                              class="action"
                              @click.prevent="removeVideo(index, item.uuid)"
                            >
                              <svg
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g id="Layer_2" data-name="Layer 2">
                                  <path
                                    d="m22 6.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"
                                    fill="#000000"
                                    style="fill: rgb(243, 18, 18)"
                                  ></path>
                                  <path
                                    d="m17 6.75h-10a.751.751 0 0 1 -.728-.932l.811-3.242a1.748 1.748 0 0 1 1.7-1.326h6.438a1.748 1.748 0 0 1 1.7 1.325l.811 3.243a.751.751 0 0 1 -.732.932zm-9.04-1.5h8.079l-.577-2.311a.25.25 0 0 0 -.243-.189h-6.438a.25.25 0 0 0 -.243.189z"
                                    fill="#000000"
                                    style="fill: rgb(243, 18, 18)"
                                  ></path>
                                  <path
                                    d="m17 22.75h-10a3.754 3.754 0 0 1 -3.75-3.75v-13a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v13a3.755 3.755 0 0 1 -3.75 3.75zm-12.25-16v12.25a2.252 2.252 0 0 0 2.25 2.25h10a2.253 2.253 0 0 0 2.25-2.25v-12.25z"
                                    fill="#000000"
                                    style="fill: rgb(243, 18, 18)"
                                  ></path>
                                  <path
                                    d="m9 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                                    fill="#000000"
                                    style="fill: rgb(243, 18, 18)"
                                  ></path>
                                  <path
                                    d="m15 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                                    fill="#000000"
                                    style="fill: rgb(243, 18, 18)"
                                  ></path>
                                </g>
                              </svg>
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-5">
                    <span>Documents</span>
                    <div
                      class="relative overflow-x-auto shadow-md sm:rounded-lg"
                    >
                      <table
                        class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
                      >
                        <thead
                          class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
                        >
                          <tr>
                            <th scope="col" class="px-6 py-3">S/N</th>
                            <th scope="col" class="px-6 py-3">Documents</th>
                            <th scope="col" class="px-6 py-3">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                            v-for="(item, index) in editModule.documents"
                            :key="index"
                          >
                            <td
                              scope="row"
                              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              {{ ++index }}
                            </td>
                            <td
                              scope="row"
                              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <iframe
                                :src="`https://docs.google.com/gview?url=${item.url}&embedded=true`"
                                style="width: 200px; height: 100px"
                                frameborder="0"
                              ></iframe>
                            </td>
                            <td
                              scope="row"
                              class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            >
                              <div class="d-flex">
                                <a :href="item.url" target="_blank" class="me-3"
                                  ><svg
                                    version="1.1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    enable-background="new 0 0 512 512"
                                    style="width: 30px"
                                  >
                                    <g>
                                      <g>
                                        <path
                                          d="m499.4,250.8c-51-86.3-143.6-140.4-243.4-140.4s-192.5,54.1-243.4,140.4c-2.1,3.1-2.1,7.3 5.32907e-15,10.4 51,86.3 143.6,140.4 243.4,140.4s192.5-54.1 243.4-140.4c2.1-3.1 2.1-7.3 0-10.4zm-243.4,130c-90.5,0-174.8-47.8-221.6-124.8 46.8-77 131.1-124.8 221.6-124.8s174.8,47.8 221.6,124.8c-46.8,77-131.1,124.8-221.6,124.8z"
                                        />
                                        <path
                                          d="m256,162.4c-52,0-93.6,41.6-93.6,93.6 0,52 41.6,93.6 93.6,93.6s93.6-41.6 93.6-93.6c0-52-41.6-93.6-93.6-93.6zm0,166.4c-40.6,0-72.8-32.3-72.8-72.8s32.3-72.8 72.8-72.8 72.8,32.3 72.8,72.8-32.2,72.8-72.8,72.8z"
                                        />
                                        <path
                                          d="m256,214.4v20.8c11.4,0 20.8,9.4 20.8,20.8s-9.4,20.8-20.8,20.8-20.8-9.4-20.8-20.8h-20.8c0,22.9 18.7,41.6 41.6,41.6 22.9,0 41.6-18.7 41.6-41.6s-18.7-41.6-41.6-41.6z"
                                        />
                                      </g>
                                    </g></svg
                                ></a>
                                <button
                                  class="action"
                                  @click.prevent="
                                    removeDocument(index, item.uuid)
                                  "
                                >
                                  <svg
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g id="Layer_2" data-name="Layer 2">
                                      <path
                                        d="m22 6.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"
                                        fill="#000000"
                                        style="fill: rgb(243, 18, 18)"
                                      ></path>
                                      <path
                                        d="m17 6.75h-10a.751.751 0 0 1 -.728-.932l.811-3.242a1.748 1.748 0 0 1 1.7-1.326h6.438a1.748 1.748 0 0 1 1.7 1.325l.811 3.243a.751.751 0 0 1 -.732.932zm-9.04-1.5h8.079l-.577-2.311a.25.25 0 0 0 -.243-.189h-6.438a.25.25 0 0 0 -.243.189z"
                                        fill="#000000"
                                        style="fill: rgb(243, 18, 18)"
                                      ></path>
                                      <path
                                        d="m17 22.75h-10a3.754 3.754 0 0 1 -3.75-3.75v-13a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v13a3.755 3.755 0 0 1 -3.75 3.75zm-12.25-16v12.25a2.252 2.252 0 0 0 2.25 2.25h10a2.253 2.253 0 0 0 2.25-2.25v-12.25z"
                                        fill="#000000"
                                        style="fill: rgb(243, 18, 18)"
                                      ></path>
                                      <path
                                        d="m9 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                                        fill="#000000"
                                        style="fill: rgb(243, 18, 18)"
                                      ></path>
                                      <path
                                        d="m15 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                                        fill="#000000"
                                        style="fill: rgb(243, 18, 18)"
                                      ></path>
                                    </g>
                                  </svg>
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
                id="dashboard"
                role="tabpanel"
                aria-labelledby="dashboard-tab"
              >
                <div
                  class="form-group shadow border border-gray-200 rounded-lg p-2 mb-4"
                >
                  <div class="w-full">
                    <label
                      for="full-name"
                      class="block mb-2 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
                      >Vidoes</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      id="updateVideo"
                      multiple
                      accept="video/mp4,video/x-m4v,video/*"
                    />
                    <button class="btn btn-primary" @click.prevent="addVideo()">
                      Add Video
                    </button>
                  </div>
                </div>
                <div
                  class="form-group shadow border border-gray-200 rounded-lg p-2 mb-4"
                >
                  <div class="w-full">
                    <label
                      for="full-name"
                      class="block mb-2 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
                      >Document</label
                    >
                    <input
                      type="file"
                      class="form-control"
                      id="updateDocument"
                      multiple
                      accept=".doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    />
                    <button
                      class="btn btn-primary"
                      @click.prevent="updateDocument()"
                    >
                      Add Document
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        id="addModal"
        tabindex="-1"
        aria-hidden="true"
        class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
      >
        <div class="relative w-full max-w-2xl max-h-full">
          <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
            <!-- Modal header -->
            <div
              class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
            >
              <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
                Add Module
              </h3>
              <button
                type="button"
                class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                data-modal-hide="addModal"
              >
                <svg
                  class="w-3 h-3"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 14"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                  />
                </svg>
                <span class="sr-only">Close modal</span>
              </button>
            </div>
            <div id="">
              <div class="p-4">
                <div class="w-full mb-3 relative">
                  <label
                    for="full-name"
                    class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                    >Module Title</label
                  >
                  <input
                    type="text"
                    id="full-name"
                    class="profile_input"
                    v-model="newModule.title"
                    required
                  />
                </div>
                <div class="mb-5">
                  <label
                    for="full-name"
                    class="block mb-4 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
                    >Vidoes</label
                  >
                  <input
                    type="file"
                    class="form-control"
                    id="addNewVideos"
                    multiple
                    accept="video/mp4,video/x-m4v,video/*"
                  />
                </div>
                <div class="mb-5">
                  <label
                    for="full-name"
                    class="block mb-4 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
                    >Document</label
                  >
                  <input
                    type="file"
                    class="form-control"
                    id="addNewDocuments"
                    multiple
                    accept=".doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                  />
                </div>
                <div class="d-flex justify-end">
                  <button
                    class="btn btn-primary"
                    style="padding: 8px 50px"
                    @click.prevent="addModule()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "edit-course",
  data() {
    return {
      options: { quality: { default: "200p" } },
      loaded: false,
      course: {
        title: "",
        description: "",
        slack_url: "",
        image: "",
        modules: [],
      },
      module: {
        title: "",
        videos: [],
        documents: [],
      },
      newModule: {
        title: "",
      },
      editModule: {
        title: "",
        videos: [],
        documents: [],
      },
      loading: false,
      form: {},
    };
  },
  methods: {
    setModule(item) {
      this.editModule = item;
    },
    updateCourse() {
      if (this.course.title.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Course title is required",
        });
        return false;
      }
      if (this.course.description.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Course description is required",
        });
        return false;
      }
      if (this.course.slack_url.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Course slack url is required",
        });
        return false;
      }

      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "teacher/update-course",
          details: this.course,
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Course updated",
          });
          console.log(resp);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    addModule() {
      if (this.newModule.title.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Module title is required",
        });
        return false;
      }
      var videos = document.getElementById("addNewVideos").files;
      var documents = document.getElementById("addNewDocuments").files;

      var formData = new FormData();
      formData.append("title", this.newModule.title);
      formData.append("uuid", this.course.uuid);
      videos.forEach((item) => {
        formData.append("videos[]", item);
      });
      documents.forEach((item) => {
        formData.append("documents[]", item);
      });

      this.$store.commit("setLoader", true);

      this.$store
        .dispatch("post", { endpoint: "teacher/add-module", details: formData })
        .then(() => {
          this.$store.commit("setLoader", false);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Module added successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    updateModule() {
      if (this.editModule.title.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Module title is required",
        });
      }
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "teacher/update-module",
          details: { uuid: this.editModule.uuid, title: this.editModule.title },
        })
        .then((resp) => {
          console.log(resp);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Module updated",
          });
          this.$store.commit("setLoader", false);
          window.setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },

    addVideo() {
      var videos = document.getElementById("updateVideo").files;
      console.log(videos);

      if (videos.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Video is required",
        });
        return false;
      }
      var formData = new FormData();
      formData.append("uuid", this.editModule.uuid);
      videos.forEach((item) => {
        formData.append("videos[]", item);
      });

      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", { endpoint: "teacher/add-video", details: formData })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Video added successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    updateDocument() {
      var formData = new FormData();
      var documents = document.getElementById("updateDocument").files;
      console.log(documents);

      if (documents.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Document is required",
        });
        return false;
      }

      formData.append("uuid", this.editModule.uuid);
      documents.forEach((item) => {
        formData.append("documents[]", item);
      });
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "teacher/add-document",
          details: formData,
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Document added successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },

    removeModule(index, uuid) {
      console.log(index);

      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "teacher/delete-module",
          details: { uuid: uuid },
        })
        .then((resp) => {
          console.log(resp);
          this.course.modules.splice(--index, 1);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Module deleted",
          });
          this.$store.commit("setLoader", false);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    removeVideo(index, uuid) {
      console.log(uuid);

      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "teacher/delete-video",
          details: { uuid: uuid },
        })
        .then((resp) => {
          console.log(resp);
          this.editModule.videos.splice(--index, 1);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Video deleted",
          });
          this.$store.commit("setLoader", false);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    removeDocument(index, uuid) {
      console.log(uuid);

      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "teacher/delete-document",
          details: { uuid: uuid },
        })
        .then((resp) => {
          console.log(resp);
          this.editModule.documents.splice(--index, 1);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Document deleted",
          });
          this.$store.commit("setLoader", false);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
    getCourse() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("get", `teacher/course/${this.$route.params.id}`)
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          this.course = resp.data;
          this.loaded = true;
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          this.$store.dispatch("handleError", err);
        });
    },
  },
  created() {
    this.getCourse();
  },
};
</script>

<style>
.editVideo .plyr--video {
  width: 137px;
}
</style>
<style scoped>
.i-vid {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  align-items: center;
  padding: 8px 13px;
  border-radius: 7px;
  margin-right: 5px;
}

.i-vid svg {
  width: 20px;
}
.action svg {
  width: 25px;
}
.profile-img img {
  border-radius: 80px;
  width: 100%;
  height: 100%;
}
</style>
