<template>
  <div class="w-full pt-6 sm:pr-5 pr-2 lg:pl-0 sm:pl-5 pl-2">
    <div class="md:flex block justify-end items-center mb-5">
      <div class="flex sm:mt-0 mt-3">
        <div class="mr-4">
          <button class="bg-white rounded-[4px] h-[41px] w-[42px] mt-1">
            <svg
              width="42"
              height="42"
              viewBox="0 0 42 42"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.220703"
                width="41.9894"
                height="41.7476"
                rx="4"
                fill="white"
              />
              <path
                d="M19.3949 28.2537C19.186 28.2532 18.9855 28.1726 18.835 28.0287C18.7583 27.9547 18.6975 27.866 18.6562 27.768C18.615 27.67 18.5941 27.5647 18.595 27.4585V21.8225L13.1784 15.2387C13.0818 15.1222 13.0209 14.9807 13.0028 14.8309C12.9846 14.6811 13.01 14.5292 13.076 14.3933C13.14 14.2573 13.2418 14.1423 13.3693 14.0618C13.4968 13.9814 13.6448 13.9388 13.7959 13.9392H28.1933C28.3447 13.9392 28.4929 13.982 28.6209 14.0624C28.7488 14.1429 28.8511 14.2578 28.9159 14.3939C28.9808 14.5299 29.0055 14.6814 28.9871 14.8308C28.9688 14.9802 28.9082 15.1213 28.8124 15.2379L23.3941 21.8225V25.868C23.3946 26.0159 23.3534 26.1609 23.275 26.2866C23.1967 26.4123 23.0844 26.5136 22.951 26.5789L19.7516 28.1695C19.6409 28.2249 19.5188 28.2537 19.3949 28.2537ZM15.4852 15.5297L20.0155 21.0352C20.1317 21.1773 20.1949 21.3548 20.1947 21.5378V26.1718L21.7944 25.3765V21.5378C21.7945 21.3543 21.8583 21.1765 21.9752 21.0344L26.5072 15.5297H15.4852Z"
                fill="#9295A3"
              />
            </svg>
          </button>
        </div>
      </div>
      <div class="flex sm:-mt-2 mt-2">
        <div class="md:w-[187px] sm:w-[166px] w-full">
          <router-link
            :to="{ name: 'create-course' }"
            class="fill-btn text-white flex-all-center !rounded-md text-base btn_category"
          >
            <span class="md:text-2xl sm:text-lg text-sm sm:mr-2 mr-1">+</span>
            Add New course
          </router-link>
        </div>
      </div>
    </div>
    <div class="md:flex block">
      <div class="md:w-[400px] w-full h-auto md:mr-4 mr-0">
        <div
          class="bg-primary flex-between-center pt-4 pb-4 mb-6 rounded-2xl h-auto px-4"
        >
          <div class="mr-5 min-w-[80px] flex-all-center">
            <img
              src="../../../assets/images/s-dashboard/Coin.png"
              class=""
              alt=""
            />
          </div>
          <div>
            <p
              class="mb-3 text-[rgba(255,255,255,1)] sm:text-base text-sm font-[MontMedium] sm:w-[240px] w-full"
            >
              Today your 2 course have been sold by new learner !
            </p>
            <button
              class="text-[rgba(106,201,231,1)] font-[MontMedium] text-sm bg-white rounded-lg w-[154px] h-[35px]"
            >
              View Details
            </button>
          </div>
        </div>

        <div class="sm:flex block w-full sm:mb-5 mb-0">
          <div
            class="md:w-[200px] sm:mb-0 mb-4 w-full flex-all-start h-[90px] mr-4 bg-white pl-4 rounded-xl"
          >
            <div
              class="w-[45px] h-[45px] bg-[rgba(77,197,145,0.15)] rounded-full flex-all-center"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.667 4.32731H8.67866L7.25616 2.90481C7.17888 2.82731 7.08704 2.76585 6.98594 2.72395C6.88483 2.68204 6.77644 2.66053 6.66699 2.66064H3.33366C2.41449 2.66064 1.66699 3.40814 1.66699 4.32731V15.994C1.66699 16.9131 2.41449 17.6606 3.33366 17.6606H16.667C17.5862 17.6606 18.3337 16.9131 18.3337 15.994V5.99398C18.3337 5.07481 17.5862 4.32731 16.667 4.32731ZM13.3337 11.8273H6.66699V10.1606H13.3337V11.8273Z"
                  fill="#4DC591"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-[rgba(4,4,4,1)] font-[MontBold]">
                {{ courses.length }}
              </p>
              <p class="text-[rgba(146,149,163,1)] text-sm">Total Courses</p>
            </div>
          </div>
          <div
            class="md:w-[200px] sm:mb-0 mb-4 w-full flex-all-start h-[90px] bg-white pl-4 rounded-xl"
          >
            <div
              class="w-[45px] h-[45px] bg-[rgba(155,161,255,0.15)] rounded-full flex-all-center"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 15.1606V5.16064C19 4.06064 18.1 3.16064 17 3.16064H4C2.9 3.16064 2 4.06064 2 5.16064V15.1606C2 16.2606 2.9 17.1606 4 17.1606H17C18.1 17.1606 19 16.2606 19 15.1606ZM8 14.1606V6.16064L14 10.1606L8 14.1606Z"
                  fill="#9BA1FF"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-[rgba(4,4,4,1)] font-[MontBold]">
                {{ totalVideo }}
              </p>
              <p class="text-[rgba(146,149,163,1)] text-sm">Total Video</p>
            </div>
          </div>
        </div>

        <div class="sm:flex block mb-5">
          <div
            class="md:w-[200px] sm:mb-0 mb-4 w-full flex-all-start h-[90px] mr-4 bg-white pl-4 rounded-xl"
          >
            <div
              class="w-[45px] h-[45px] bg-[rgba(255,187,68,0.15)] rounded-full flex-all-center"
            >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.667 5.99398V4.32731C16.667 3.40814 15.9195 2.66064 15.0003 2.66064H4.16699C2.78866 2.66064 1.66699 3.78231 1.66699 5.16064V15.1606C1.66699 16.9948 3.16199 17.6606 4.16699 17.6606H16.667C17.5862 17.6606 18.3337 16.9131 18.3337 15.994V7.66064C18.3337 6.74148 17.5862 5.99398 16.667 5.99398ZM15.0003 13.494H13.3337V10.1606H15.0003V13.494ZM4.16699 5.99398C3.95243 5.98438 3.74983 5.89239 3.60139 5.73716C3.45295 5.58193 3.3701 5.37543 3.3701 5.16064C3.3701 4.94586 3.45295 4.73936 3.60139 4.58413C3.74983 4.4289 3.95243 4.33691 4.16699 4.32731H15.0003V5.99398H4.16699Z"
                  fill="#FFBB44"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-[rgba(4,4,4,1)] font-[MontBold]">$0</p>
              <p class="text-[rgba(146,149,163,1)] text-sm">Total Earning</p>
            </div>
          </div>
          <div
            class="md:w-[200px] w-full flex-all-start h-[90px] bg-white pl-4 rounded-xl"
          >
            <div
              class="w-[45px] h-[45px] bg-[rgba(77,197,145,0.15)] rounded-full flex-all-center"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0065 3.74939C12.831 3.74939 12.6885 3.89195 12.6885 4.06763C12.6885 4.08395 12.6954 4.09811 12.6978 4.11395H12.6885V11.6591C12.6885 11.8347 12.831 11.9773 13.0065 11.9773H20.5514V11.9679C20.5672 11.9703 20.5814 11.9773 20.5979 11.9773C20.7734 11.9773 20.9159 11.8347 20.9159 11.6591C20.8708 7.31027 17.3553 3.79475 13.0065 3.74939Z"
                  fill="#FF5374"
                />
                <path
                  d="M19.0761 13.4911C19.0761 13.3154 18.9338 13.1728 18.7581 13.1728H11.8224C11.738 13.1728 11.6571 13.1392 11.5975 13.0795C11.5379 13.0199 11.5044 12.939 11.5044 12.8546V5.94427H11.495C11.4974 5.92843 11.5044 5.91427 11.5044 5.89795C11.5044 5.72203 11.3618 5.57971 11.1861 5.57971C11.1785 5.57971 11.1722 5.58355 11.1648 5.58403V5.58187C11.1365 5.58163 11.1084 5.57971 11.0801 5.57971C6.66406 5.57971 3.08398 9.15979 3.08398 13.5758C3.08398 17.9918 6.66406 21.5719 11.0801 21.5719C15.4961 21.5719 19.0761 17.9918 19.0761 13.5758C19.0761 13.5504 19.0749 13.5252 19.0745 13.4995C19.0745 13.4964 19.0761 13.494 19.0761 13.4911Z"
                  fill="#FF5374"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-[rgba(4,4,4,1)] font-[MontBold]">
                {{ totalStudent }}
              </p>
              <p class="text-[rgba(146,149,163,1)] text-sm">Total Student</p>
            </div>
          </div>
        </div>

        <div class="bg-white rounded-2xl h-[280px] md:mb-0 mb-4"></div>
      </div>
      <div
        class="w-full h-auto bg-white sm:rounded-3xl pt-4 px-5 overflow-auto courses-table"
      >
        <div class="relative overflow-x-auto table_scrollbar">
          <table
            class="w-full text-sm text-left text-[rgba(4,4,4,1)] font-[MontMedium] table_ tr"
          >
            <thead
              class="text-xs text-[rgba(4,4,4,1)] bg-white !font-[MontMedium]"
            >
              <tr>
                <th scope="col" class="px-6 py-3 capitalize">Course Name</th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center">Module</div>
                </th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center">Video</div>
                </th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center">Student</div>
                </th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center">Price</div>
                </th>
                <!-- <th scope="col" class="px-6 py-3">
                  <div class="flex items-center">
                    Earning
                    
                  </div>
                </th> -->
              </tr>
            </thead>
            <tbody v-if="courses.length > 0">
              <tr
                class="bg-white border border-[rgba(243,245,248,1)] !rounded-xl tr_table"
                v-for="(item, index) in courses"
                :key="index"
                @click="
                  $router.push({
                    name: 'edit-course',
                    params: { id: item.uuid },
                  })
                "
              >
                <th
                  scope="row"
                  class="px-6 !py-6 font-medium text-gray-900 whitespace-nowrap flex-all-start"
                >
                  <div class="w-[40px] h-[40px] mr-3">
                    <img :src="item.image" alt="" />
                  </div>
                  <p class="text-[rgba(4,4,4,1)]">{{ item.title }}</p>
                </th>
                <td class="px-6 !py-6 text-[rgba(146,149,163,1)]">
                  {{ item.modules.length }}
                </td>
                <td class="px-6 !py-6 text-[rgba(146,149,163,1)]">
                  {{ calculateTotalVideos(item) }}
                </td>
                <td class="px-6 !py-6 text-[rgba(146,149,163,1)]">
                  {{ item.students_count }}
                </td>
                <td class="px-6 !py-6 text-[rgba(42,43,97,1)]">$0</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="5">
                  <div
                    class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
                    role="alert"
                    v-if="courses.length < 1"
                  >
                    <span class="block sm:inline">No courses found</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div
            class="flex w-full flex-all-center mt-8 pb-3"
            v-if="courses.length > 15"
          >
            <ul class="flex">
              <li>
                <div
                  class="w-[35px] h-[35px] border rounded-full flex-all-center shadow-xl shadow-[rgba(241,224,224,0.06)] mr-3 hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  <i class="fa-solid fa-less-than text-sm"></i>
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  1
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] bg-[rgba(77,197,145,1)] text-white border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  2
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  3
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  4
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] text-sm border rounded-full flex-all-center shadow-xl shadow-[rgba(241,224,224,0.06)] mr-3 hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  <i class="fa-solid fa-greater-than text-sm"></i>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: "course",
  data() {
    return {
      courses: [],
      totalVideo: 0,
      totalStudent: 0,
    };
  },
  methods: {
    getCourses() {
      this.$store
        .dispatch("get", "teacher/courses")
        .then((resp) => {
          console.log(resp);
          this.courses = resp.data;
          this.courses.forEach((item) => {
            this.totalVideo += this.calculateTotalVideos(item);
            this.totalStudent += item.students_count;
          });
        })
        .catch((error) => {
          this.$store.dispatch("handleError", error);
        });
    },
    calculateTotalVideos(course) {
      var total = 0;
      course.modules.forEach((item) => {
        total += item.videos.length;
      });
      return total;
    },
  },
  created() {
    this.getCourses();
  },
};
</script>

<style scoped>
.courses-table {
  height: fit-content;
  padding-bottom: 25px;
}
</style>
