<template>
  <div class="loader">
    <Spinner />
  </div>
</template>

<script>
import Spinner from "@/components/MiniSpinner.vue";
export default {
  name: "loader-screen",
  components: { Spinner },
};
</script>

<style scoped>
.loader {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #8080808f;
  position: fixed;
  z-index: 99999;
}
</style>
