import Vuex from "vuex";
import Vue from "vue";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";
Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    loader: false,
    // endpoint: 'process.env.VUE_APP_ENDPOINT',
    endpoint: "https://api.hicdemo.co.uk/api/v1/",
    user: null,
    token: null,
    notification: {
      type: 0,
      message: "",
    },
  },
  getters,
  mutations,
  actions,
});
export default store;
