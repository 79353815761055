import Login from "@/views/auth/login.vue";
import StudentSignup from "@/views/auth/student/signup.vue";
import TeacherSignup from "@/views/auth/teacher/signup.vue";

const publicRoutes = [
  {
    path: "/",
    component: Login,
    name: "login",
  },
  {
    path: "/student/register",
    component: StudentSignup,
    name: "register",
  },
  {
    path: "/teacher/register",
    component: TeacherSignup,
    name: "register",
  },
];

export default publicRoutes;
