import Vue from "vue";
import App from "./App.vue";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";
// import VueRouter from "vue-router";
import router from "./routes/index";
import store from "./store/index";
import mixins from "./mixin/index.js";
import "./index.css";
import "../src/assets/css/style.css";
import "../src/assets/css/index.css";
import "../src/assets/css/main.css";

Vue.config.productionTip = false;
Vue.mixin(mixins);
Vue.use(VuePlyr, {
  plyr: {},
});
// Vue.use(VueRouter);
// const router = new VueRouter({
//   routes: Routes,
//   mode: "history",
// });
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
