<template>
  <div class="flex w-full pt-28 s_dash_wrap sm:pr-5 pr-2 lg:pl-0 sm:pl-5 pl-2">
    <div class="w-ful l">
      <div
        class="bg-[rgba(42,43,97,1)] sm:rounded-[23px] rounded-lg pl-6 pr-14 flex justify-between items-center sm:h-[140px] h-auto mb-6 s_blue_cont"
      >
        <div class="sm:py-0 py-3">
          <p class="text-white font-[MontMedium] sm:text-2xl text-base mb-1">
            Hi {{ user.firstname }}!
          </p>
          <p
            class="text-white font-[MontRegular] sm:text-base text-sm sm:w-[300px] w-full"
          >
            You have {{ schedules.length }} upcoming schedule.
          </p>
        </div>
        <div class="w-[248px] -mt-[49px] s_illustration md:block hidden">
          <img
            src="../../../assets/images/s-dashboard/Illustration.png"
            alt="avatar"
          />
        </div>
      </div>
      <!-- <div class="flex s_chart pb-2">
        <div
          class="sm:min-w-[330px] w-full mb-4 shadow-xl shadow-[rgba(241,224,224,0.3)] mr-7 rounded-[23px] bg-white h-[240px]"
        ></div>
        <div
          class="w-full rounded-[23px] bg-white h-[240px] shadow-xl shadow-[rgba(241,224,224,0.3)]"
        ></div>
      </div> -->
      <div
        class="w-[100%] !mb-8 shadow-xl shadow-[rgba(241,224,224,0.3)] rounded-[23px] bg-white h-auto pb-6 sm:px-5 px-2"
      >
        <div class="sm:flex block justify-between items-center py-1">
          <p class="font-[MontMedium] text-lg sm:pt-0 pt-3">My Courses</p>
          <div class="mb-4">
            <ul
              class="flex flex-wrap text-center py-3"
              id="myTab"
              data-tabs-toggle="#myTabContent"
              role="tablist"
            >
              <li class="mr-2" role="presentation">
                <button
                  class="inline-block sm:p-4 pr-3 text-[rgba(146,149,163,1)] hover:text-[rgba(42,43,97,1)] font-[MontMedium]"
                  id="profile-tab"
                  data-tabs-target="#all"
                  type="button"
                  role="tab"
                  aria-controls="all"
                  aria-selected="false"
                >
                  All
                </button>
              </li>
              <li class="mr-2" role="presentation">
                <button
                  class="inline-block sm:p-4 pr-3 text-[rgba(146,149,163,1)] hover:text-[rgba(42,43,97,1)] font-[MontMedium]"
                  id="ongoing-tab"
                  data-tabs-target="#ongoing"
                  type="button"
                  role="tab"
                  aria-controls="ongoing"
                  aria-selected="false"
                >
                  Ongoing
                </button>
              </li>
              <li role="presentation">
                <button
                  class="inline-block sm:p-4 p-0 text-[rgba(146,149,163,1)] hover:text-[rgba(42,43,97,1)] font-[MontMedium]"
                  id="complete-tab"
                  data-tabs-target="#complete"
                  type="button"
                  role="tab"
                  aria-controls="complete"
                  aria-selected="false"
                >
                  Complete
                </button>
              </li>
            </ul>
          </div>
        </div>

        <div id="myTabContent">
          <div
            class="hidden"
            id="all"
            role="tabpanel"
            aria-labelledby="all-tab"
          >
            <div class="" v-if="courses.length > 0">
              <div
                class="py-2 px-4 rounded-lg border-2 border-[rgba(243,245,248,1)] h-[70px] courses_wrap mt-1"
                v-for="(item, index) in courses"
                :key="index"
              >
                <div class="flex justify-between items-center">
                  <div class="w-auto">
                    <div class="flex justify-start items-center">
                      <div class="mr-4 sm:w-[50px] w-[35px]">
                        <img
                          :src="item.image"
                          class="rounded-full"
                          alt="grph-design"
                        />
                      </div>
                      <div>
                        <p
                          class="text-[rgba(4,4,4,1)] font-[MontMedium] sm:text-base text-sm"
                        >
                          {{ item.title }}
                        </p>
                        <p
                          class="text-[rgba(146,149,163,1)] sm:text-sm text-xs font-[MontMedium]"
                        >
                          By {{ item.teacher.firstname }}
                          {{ item.teacher.lastname }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-wrap justify-between items-center">
                    <!-- <div class="flex justify-start items-center">
                    <p class="text-[rgba(4,4,4,1)] font-[MontMedium] text-sm">
                      25%
                    </p>
                    <div class="bg-gray-200 rounded-full h-1.5 w-[100px] ml-4">
                      <div
                        class="bg-[rgba(42,43,97,1)] h-1.5 rounded-full"
                        style="width: 35%"
                      ></div>
                    </div>
                  </div> -->

                    <!-- <div class="flex justify-start items-center">
                    <span class="mr-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.44745 11.3593L12.0517 14.1332L10.83 8.90422L14.8986 5.38651L9.5386 4.93213L7.44745 0L5.35629 4.93213L0 5.38651L4.06858 8.90422L2.84691 14.1368L7.44745 11.3593Z"
                          fill="#F5A623"
                        />
                      </svg>
                    </span>
                    <p class="font-[MontMedium] text-sm">4.3</p>
                  </div> -->

                    <div>
                      <router-link
                        :to="{ name: 'course', params: { id: item.uuid } }"
                        class="view-btn"
                        >View Course</router-link
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span class="block sm:inline">No registered courses</span>
            </div>
          </div>

          <div
            class="hidden"
            id="ongoing"
            role="tabpanel"
            aria-labelledby="ongoing-tab"
          >
            <div
              class="py-2 px-4 rounded-lg border-2 border-[rgba(243,245,248,1)] h-[70px] courses_wrap"
              v-for="(item, index) in courses"
              :key="index"
            >
              <div class="flex justify-between items-center courses_content">
                <div class="sm:min-w-[290px] w-auto">
                  <div class="flex justify-start items-center">
                    <div class="mr-4 sm:w-[50px] w-[35px]">
                      <img
                        :src="item.image"
                        class="rounded-full"
                        alt="grph-design"
                      />
                    </div>
                    <div>
                      <p
                        class="text-[rgba(4,4,4,1)] font-[MontMedium] sm:text-base text-sm"
                      >
                        {{ item.title }}
                      </p>
                      <p
                        class="text-[rgba(146,149,163,1)] sm:text-sm text-xs font-[MontMedium]"
                      >
                        By {{ item.teacher.firstname }}
                        {{ item.teacher.lastname }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="w-full flex flex-wrap justify-between items-center">
                  <!-- <div class="flex justify-start items-center">
                    <p class="text-[rgba(4,4,4,1)] font-[MontMedium] text-sm">
                      25%
                    </p>
                    <div class="bg-gray-200 rounded-full h-1.5 w-[100px] ml-4">
                      <div
                        class="bg-[rgba(42,43,97,1)] h-1.5 rounded-full"
                        style="width: 35%"
                      ></div>
                    </div>
                  </div> -->

                  <!-- <div class="flex justify-start items-center">
                    <span class="mr-2">
                      <svg
                        width="15"
                        height="15"
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.44745 11.3593L12.0517 14.1332L10.83 8.90422L14.8986 5.38651L9.5386 4.93213L7.44745 0L5.35629 4.93213L0 5.38651L4.06858 8.90422L2.84691 14.1368L7.44745 11.3593Z"
                          fill="#F5A623"
                        />
                      </svg>
                    </span>
                    <p class="font-[MontMedium] text-sm">4.3</p>
                  </div> -->

                  <div>
                    <router-link
                      :to="{ name: 'course', params: { id: item.uuid } }"
                      class="view-btn"
                      >View Course</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="hidden"
            id="complete"
            role="tabpanel"
            aria-labelledby="complete-tab"
          >
            <div
              class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
              role="alert"
            >
              <span class="block sm:inline">No courses completed yet</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="sm:min-w-[355px] h-full bg-white rounded-[23px] pt-3 md:ml-5 ml-0 sm:px-5 px-2"
    >
      <div id="Upcoming-Task" class="mb-6">
        <div class="flex justify-between items-start mb-5">
          <div>
            <p class="font-[MontMedium] text-lg pb-2">Upcoming Task</p>
          </div>
          <div>
            <router-link
              to="/student/courses/schedule"
              class="text-[rgba(106,201,231,1)] text-xs font-[MontMedium] cursor-pointer"
            >
              See all
            </router-link>
          </div>
        </div>

        <div
          class="py-2 pl-1 mb-2 rounded-lg border-2 border-[rgba(243,245,248,1)] h-[70px] courses_wrap"
          v-for="(schedule, index) in schedules"
          :key="index"
        >
          <div class="flex justify-between items-center pr-4">
            <div class="">
              <div class="flex justify-start items-center">
                <div class="mr-3 sm:w-[50px] w-[35px]">
                  <img
                    src="../../../assets/images/s-dashboard/discussion-algorithm.png"
                    alt="grph-design"
                  />
                </div>
                <div>
                  <p
                    class="text-[rgba(4,4,4,1)] font-[MontMedium] sm:text-sm text-xs mb-1"
                  >
                    {{ schedule.title }}
                  </p>
                  <p
                    class="text-[rgba(146,149,163,1)] text-xs font-[MontMedium]"
                  >
                    {{ formatDateTime(schedule.start) }}
                    {{ new Date(schedule.start).toLocaleTimeString() }} -
                    {{ formatDateTime(schedule.end) }}
                    {{ new Date(schedule.end).toLocaleTimeString() }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          v-if="schedules.length < 1"
          class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
          role="alert"
        >
          No upcoming schedules
        </p>
      </div>
      <!-- <div id="Payment-History" class="pb-6">
        <div class="flex justify-between items-center mb-4">
          <div>
            <p class="font-[MontMedium] text-lg pb-2">Payment History</p>
          </div>
          <div>
            <p
              class="text-[rgba(106,201,231,1)] text-xs font-[MontMedium] cursor-pointer"
            >
              See all
            </p>
          </div>
        </div>

        <div
          class="bg-[rgba(249,249,249,1)] rounded-md flex justify-between items-center py-3.5 px-5 mb-3.5"
        >
          <p class="text-[rgba(146,149,163,1)] font-[MontMedium] text-sm">
            Wireframe & Prototype :
          </p>
          <p
            class="text-[rgba(4,4,4,1)] font-[MontMedium] sm:text-lg text-base"
          >
            $20
          </p>
        </div>
        <div
          class="bg-[rgba(249,249,249,1)] rounded-md flex justify-between items-center py-3.5 px-5"
        >
          <p class="text-[rgba(146,149,163,1)] font-[MontMedium] text-sm">
            MSc in Machine Learning:
          </p>
          <p
            class="text-[rgba(4,4,4,1)] font-[MontMedium] sm:text-lg text-base"
          >
            $40
          </p>
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import { initFlowbite } from "flowbite";
  export default {
    name: "student-dashboard",
    computed: {
      ...mapState({
        user: (state) => state.user,
      }),
    },
    mounted() {
      initFlowbite();
    },
    data() {
      return {
        courses: [],
        schedules: [],
      };
    },
    methods: {
      getCourses() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("get", "student/my-courses")
          .then((resp) => {
            this.$store.commit("setLoader", false);
            console.log(resp);
            this.courses = resp.data;
            this.loaded = true;
          })
          .catch((err) => {
            this.$store.commit("setLoader", false);
            this.$store.dispatch("handleError", err);
          });
      },
      getSchedules() {
        this.$store
          .dispatch("get", "student/upcoming-schedules")
          .then((resp) => {
            this.schedules = resp.data;
            this.schedules.forEach((item) => {
              item.start = item.start.replace("T", " ");
              item.end = item.end.replace("T", " ");
            });
            console.log(this.schedules);
          })

          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
    },
    created() {
      this.getCourses();
      this.getSchedules();
    },
  };
</script>

<style>
  .view-btn {
    display: flex;
    height: 38px;
    width: 112px;
    align-items: center;
    justify-content: center;

    /* border-radius: 13px;
  border-width: 2px;
  border-color: rgba(42, 43, 97, 1);
  font-family: MontMedium;
  font-size: 0.75rem;
  line-height: 1rem;
  transition-duration: 200ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1); */
  }
</style>
