<template>
  <div class="sm:bg-none bg-[#d6fcf9a1] min-h-[100vh]">
    <GradientBg />
    <notification />
    <div class="h-full relative z-2">
      <Sidebar />
      <Navbar />
      <div class="lg:ml-[309px] ml-0 mt-[60px]">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/student-dashboard/sidebar.vue";
import Navbar from "@/components/student-dashboard/navbar.vue"
import GradientBg from "@/components/gradient-bg.vue";
import notification from "@/components/notification.vue";
export default {
  name: "student-layout",
  components: {
    Sidebar,
    Navbar,
    GradientBg,
    notification,
  },
};
</script>
