import axios from "axios";

export default {
  handleError(context, error) {
    // console.log("fff");
    // console.log(error.response);
    if (error.request.status == 422) {
      var resp = JSON.parse(error.request.response);

      var err = resp.errors;
      console.log("fff");
      console.log(err);
      var msg = "";
      for (var item in err) {
        msg = err[item][0];
        break;
      }
      console.log(msg);
      context.commit("setNotification", { type: 2, message: msg });
      return msg;
    } else if (error.request.status == 303) {
      resp = JSON.parse(error.request.response);
      context.commit("setNotification", { type: 2, message: resp.error });
    } else if (error.request.status == 404) {
      resp = JSON.parse(error.request.response);
      msg = "Request not found";
      context.commit("setNotification", { type: 2, message: msg });
    } else if (error.request.status == 400) {
      resp = JSON.parse(error.request.response);
      msg = resp.message;
      context.commit("setNotification", { type: 2, message: msg });
    } else if (error.request.status == 401) {
      msg = "Oops! Authentication error, Please login again";
      context.commit("setNotification", { type: 2, message: msg });

      context.commit("logout");
    } else {
      msg = "Oops! server error, Please try again";
      context.commit("setNotification", { type: 2, message: msg });
    }
  },

  post(context, data) {
    return new Promise((resolve, reject) => {
      axios
        .post(context.state.endpoint + data.endpoint, data.details, {
          headers: { Authorization: "Bearer " + context.state.token },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          console.log(error);
          context.dispatch("handleError", error);
          reject(error);
        });
    });
  },

  get(context, endpoint) {
    return new Promise((resolve, reject) => {
      axios
        .get(context.state.endpoint + endpoint, {
          headers: {
            Authorization: "Bearer " + context.state.token,
          },
        })
        .then((data) => {
          resolve(data);
        })
        .catch((error) => {
          context.dispatch("handleError", error);
          reject(error);
        });
    });
  },
};
