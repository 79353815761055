import router from "@/routes";
export default {
  setNotification(state, data) {
    state.notification.type = data.type;
    state.notification.message = data.message;
    setTimeout(() => {
      state.notification.type = 0;
      state.notification.message = "";
    }, 6000);
  },

  setLoader(state, data) {
    state.loader = data;
  },

  setUser(state, data) {
    state.user = data.user;
    state.token = data.token;

    var result = encodeURIComponent(JSON.stringify(data));
    localStorage.setItem("user", result);
  },

  getUser(state) {
    var data = localStorage.getItem("user");
    console.log(data);
    if (data) {
      data = decodeURIComponent(data);
      data = JSON.parse(data);
      console.log(data);

      if (data) {
        state.user = data.user;
        state.token = data.token;
      }
    }
  },

  logout(state) {
    state.authenticated = false;
    this.authstatus = false;
    window.localStorage.removeItem("user");
    state.user = {};
    state.token = null;
    if (
      router.history.current.name != "login" &&
      router.history.current.name != "register"
    ) {
      window.location.href = "/";
    }
  },
};
