<template>
  <div class="bg-white rounded-[25px] p-5 mt-5 h-max min-h-[60vh]">
    <div class="h-full">
      <div v-if="!setModule">
        <h4>Create Course</h4>
        <div class="flex items-center mt-6">
          <div class="w-full sm:mr-5">
            <div class="sm:flex justify-between w-full">
              <div class="mb-6 w-full sm:mr-5 relative">
                <label
                  for="full-name"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Course Title</label
                >
                <input
                  type="text"
                  id="full-name"
                  class="profile_input"
                  v-model="course.title"
                  required
                />
              </div>
            </div>
            <div class="sm:flex justify-between w-full">
              <div class="mb-6 w-full sm:mr-5 relative">
                <label
                  for="full-name"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Slack Channel Url</label
                >
                <input
                  type="text"
                  id="slackUrl"
                  class="profile_input"
                  v-model="course.slack_url"
                  required
                />
              </div>
            </div>
            <div class="mb-6 w-full">
              <label
                for="full-name"
                class="block mb-4 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
                >Course Image</label
              >
              <input
                type="file"
                id="courseImage"
                accept="image/*"
                class="form-control"
              />
            </div>
            <div class="mb-6 w-full relative">
              <label
                for="full-name"
                class="block mb-4 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                >Description</label
              >

              <textarea
                id="full-name"
                class="profile_input pt-[35px!important] min-h-[100px] placeholder:text-xs"
                v-model="course.description"
                required
              ></textarea>
            </div>
            <div class="w-full text-left">
              <button
                class="green-btn mb-8 max-w-[170px]"
                @click="toggleModule()"
              >
                Next
              </button>
            </div>
          </div>
          <div class="w-[20%] sm:flex hidden">
            <img
              src="../../../assets/images/createCourse.png"
              class="md:w-[300px] w-full mt-[-30px] ml-auto h-full"
            />
          </div>
        </div>
      </div>
      <div class="" v-else>
        <div class="d-flex justify-content-between align-items-center">
          <p>Create class module</p>
          <div class="d-flex">
            <button class="btn btn-primary" @click="toggleModule()">
              Previous
            </button>
            <button
              @click.prevent="createCourse()"
              class="btn btn-success ml-3"
              v-if="course.modules.length > 0"
              :disabled="loading"
            >
              <span v-if="!loading">Submit</span>
              <span v-else>Loading...</span>
            </button>
          </div>
        </div>

        <div class="mt-6">
          <div class="w-full mb-3 relative">
            <label
              for="full-name"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Module Title</label
            >
            <input
              type="text"
              id="full-name"
              class="profile_input"
              v-model="module.title"
              required
            />
          </div>
          <div class="mb-5">
            <label
              for="full-name"
              class="block mb-4 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Vidoes</label
            >
            <input
              type="file"
              class="form-control"
              id="moduleVideos"
              multiple
              accept="video/mp4,video/x-m4v,video/*"
            />
          </div>
          <div class="mb-5">
            <label
              for="full-name"
              class="block mb-4 text-xs font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Document</label
            >
            <input
              type="file"
              class="form-control"
              id="moduleDocuments"
              multiple
              accept=".doc,.pdf,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            />
          </div>
          <div class="d-flex justify-end">
            <button
              class="btn btn-primary"
              style="padding: 8px 50px"
              @click.prevent="addModule()"
            >
              Add
            </button>
          </div>
        </div>

        <div class="relative overflow-x-auto shadow-md sm:rounded-lg mt-5">
          <table
            class="w-full text-sm text-left text-gray-500 dark:text-gray-400"
          >
            <thead
              class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400"
            >
              <tr>
                <th scope="col" class="px-6 py-3">S/N</th>
                <th scope="col" class="px-6 py-3">Module Topic</th>
                <th scope="col" class="px-6 py-3">Videos</th>
                <th scope="col" class="px-6 py-3">Documents</th>
                <th scope="col" class="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="bg-white border-b dark:bg-gray-900 dark:border-gray-700"
                v-for="(item, index) in course.modules"
                :key="index"
              >
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ ++index }}
                </td>
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  {{ item.title }}
                </td>
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div class="flex flex-wrap">
                    <div
                      class="i-vid"
                      v-for="(vid, index2) in Object.keys(item.videos)"
                      :key="index2"
                    >
                      <span>{{ item.videos[index2].name }}</span>
                      <button
                        class="ml-3"
                        @click.prevent="removeVideo(index, index2)"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Layer_2" data-name="Layer 2">
                            <path
                              d="m22 6.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m17 6.75h-10a.751.751 0 0 1 -.728-.932l.811-3.242a1.748 1.748 0 0 1 1.7-1.326h6.438a1.748 1.748 0 0 1 1.7 1.325l.811 3.243a.751.751 0 0 1 -.732.932zm-9.04-1.5h8.079l-.577-2.311a.25.25 0 0 0 -.243-.189h-6.438a.25.25 0 0 0 -.243.189z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m17 22.75h-10a3.754 3.754 0 0 1 -3.75-3.75v-13a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v13a3.755 3.755 0 0 1 -3.75 3.75zm-12.25-16v12.25a2.252 2.252 0 0 0 2.25 2.25h10a2.253 2.253 0 0 0 2.25-2.25v-12.25z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m9 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m15 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </td>
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <div class="flex flex-wrap">
                    <div
                      class="i-vid"
                      v-for="(vid, index2) in Object.keys(item.documents)"
                      :key="index2"
                    >
                      <span>{{ item.documents[index2].name }}</span>
                      <button
                        class="ml-3"
                        @click.prevent="removeDocument(index, index2)"
                      >
                        <svg
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g id="Layer_2" data-name="Layer 2">
                            <path
                              d="m22 6.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m17 6.75h-10a.751.751 0 0 1 -.728-.932l.811-3.242a1.748 1.748 0 0 1 1.7-1.326h6.438a1.748 1.748 0 0 1 1.7 1.325l.811 3.243a.751.751 0 0 1 -.732.932zm-9.04-1.5h8.079l-.577-2.311a.25.25 0 0 0 -.243-.189h-6.438a.25.25 0 0 0 -.243.189z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m17 22.75h-10a3.754 3.754 0 0 1 -3.75-3.75v-13a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v13a3.755 3.755 0 0 1 -3.75 3.75zm-12.25-16v12.25a2.252 2.252 0 0 0 2.25 2.25h10a2.253 2.253 0 0 0 2.25-2.25v-12.25z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m9 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                            <path
                              d="m15 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                              fill="#000000"
                              style="fill: rgb(243, 18, 18)"
                            ></path>
                          </g>
                        </svg>
                      </button>
                    </div>
                  </div>
                </td>
                <td
                  scope="row"
                  class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                >
                  <button class="action" @click="removeModule(index)">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                      <g id="Layer_2" data-name="Layer 2">
                        <path
                          d="m22 6.75h-20a.75.75 0 0 1 0-1.5h20a.75.75 0 0 1 0 1.5z"
                          fill="#000000"
                          style="fill: rgb(243, 18, 18)"
                        ></path>
                        <path
                          d="m17 6.75h-10a.751.751 0 0 1 -.728-.932l.811-3.242a1.748 1.748 0 0 1 1.7-1.326h6.438a1.748 1.748 0 0 1 1.7 1.325l.811 3.243a.751.751 0 0 1 -.732.932zm-9.04-1.5h8.079l-.577-2.311a.25.25 0 0 0 -.243-.189h-6.438a.25.25 0 0 0 -.243.189z"
                          fill="#000000"
                          style="fill: rgb(243, 18, 18)"
                        ></path>
                        <path
                          d="m17 22.75h-10a3.754 3.754 0 0 1 -3.75-3.75v-13a.75.75 0 0 1 .75-.75h16a.75.75 0 0 1 .75.75v13a3.755 3.755 0 0 1 -3.75 3.75zm-12.25-16v12.25a2.252 2.252 0 0 0 2.25 2.25h10a2.253 2.253 0 0 0 2.25-2.25v-12.25z"
                          fill="#000000"
                          style="fill: rgb(243, 18, 18)"
                        ></path>
                        <path
                          d="m9 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                          fill="#000000"
                          style="fill: rgb(243, 18, 18)"
                        ></path>
                        <path
                          d="m15 17.75a.75.75 0 0 1 -.75-.75v-6a.75.75 0 0 1 1.5 0v6a.75.75 0 0 1 -.75.75z"
                          fill="#000000"
                          style="fill: rgb(243, 18, 18)"
                        ></path>
                      </g>
                    </svg>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
/* eslint-disable */
export default {
  name: "create-course",
  data() {
    return {
      setModule: false,
      course: {
        title: "",
        description: "",
        slack_url: "",
        image: "",
        modules: [],
      },
      module: {
        title: "",
        videos: [],
        documents: [],
      },
      loading: false,
      form: {},
      afterLoop: false,
    };
  },
  watch: {
    afterLoop(newValue, oldValue) {
      if (newValue) {
        console.log(index, "finally loop done again");
      }
    },
  },
  methods: {
    toggleModule() {
      if (!this.setModule) {
        if (this.course.title.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Course topic is required",
          });
          return false;
        }
        if (this.course.slack_url.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Slack url is required",
          });
          return false;
        }
        var course_image = document.getElementById("courseImage");
        console.log(course_image);
        if (!course_image.files[0]) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Course image is required",
          });
          return false;
        } else {
          this.course.image = course_image.files[0];
        }

        if (this.course.description.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Course description is required",
          });
          return false;
        }
      }
      this.setModule = !this.setModule;
    },
    addModule() {
      if (this.module.title.length < 1) {
        this.$store.commit("setNotification", {
          type: 2,
          message: "Module title is required",
        });
        return false;
      }
      var videos = document.getElementById("moduleVideos").files;
      var documents = document.getElementById("moduleDocuments").files;

      this.module.videos = [...videos];
      this.module.documents = [...documents];
      this.course.modules.push({ ...this.module });
      console.log(this.course);

      this.module.title = "";
      document.getElementById("moduleVideos").value = "";
      document.getElementById("moduleDocuments").value = "";
    },
    removeModule(index) {
      console.log(index);
      this.course.modules.splice(--index, 1);
    },
    removeVideo(index, index2) {
      var a = --index;

      console.log(index, index2);

      this.course.modules.forEach((item, key) => {
        console.log(key, item);
        if (key == a) {
          console.log(a);
          item.videos.splice(index2, 1);
        }
      });
    },
    removeDocument(index, index2) {
      var a = --index;

      // console.log(index, index2);

      this.course.modules.forEach((item, key) => {
        if (key == a) {
          item.documents.splice(index2, 1);
        }
      });
    },
    async createCourse() {
      console.log("jjjg");
      console.log(this.course);
      this.loading = true;
      this.$store.commit("setLoader", true);
      this.form = new FormData();
      this.form.append("title", this.course.title);
      this.form.append("description", this.course.description);
      this.form.append("slack_url", this.course.slack_url);
      // console.log(document.getElementById("courseImage"));
      var formData = new FormData();
      formData.append("upload_preset", "dz7qqaehx");
      formData.append("file", this.course.image);
      formData.append("upload_preset", "vue-image-video");
      await axios
        .post("https://api.cloudinary.com/v1_1/dz7qqaehx/upload", formData, {
          headers: {
            Accept: "application/json",
          },
        })
        .then((resp) => {
          this.form.append("course_image", resp.data.secure_url);
          console.log(resp);
        })
        .catch((err) => {
          this.$store.commit("setLoader", false);
          console.log(err);
        });

      for (var index = 0; index < this.course.modules.length; index++) {
        this.form.append(
          `module[${index}][title]`,
          this.course.modules[index]["title"]
        );
        for (
          var index2 = 0;
          index2 < this.course.modules[index]["videos"].length;
          index2++
        ) {
          var formData2 = new FormData();
          formData2.append("upload_preset", "dz7qqaehx");
          formData2.append(
            "file",
            this.course.modules[index]["videos"][index2]
          );
          formData2.append("upload_preset", "vue-image-video");
          await axios
            .post(
              "https://api.cloudinary.com/v1_1/dz7qqaehx/upload",
              formData2,
              {
                headers: {
                  Accept: "application/json",
                },
              }
            )
            .then((resp) => {
              console.log("lll", index2);
              this.form.append(
                `module[${index}][videos][${index2}]`,
                resp.data.secure_url
              );
              console.log(resp);
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
              this.$store.commit("setNotification", {
                type: 2,
                message: "Error creating course",
              });
            });
        }
        for (
          var index2 = 0;
          index2 < this.course.modules[index]["documents"].length;
          index2++
        ) {
          var formData2 = new FormData();
          formData2.append("upload_preset", "dz7qqaehx");
          formData2.append(
            "file",
            this.course.modules[index]["documents"][index2]
          );
          formData2.append("upload_preset", "vue-image-video");
          await axios
            .post(
              "https://api.cloudinary.com/v1_1/dz7qqaehx/upload",
              formData2,
              {
                headers: {
                  Accept: "application/json",
                },
              }
            )
            .then((resp) => {
              console.log("lll", index2);
              this.form.append(
                `module[${index}][documents][${index2}]`,
                resp.data.secure_url
              );
              console.log(resp);
            })
            .catch((err) => {
              this.loading = false;
              console.log(err);
              this.$store.commit("setNotification", {
                type: 2,
                message: "Error creating course",
              });
              this.$store.commit("setLoader", false);
            });
        }
      }
      this.$store
        .dispatch("post", {
          endpoint: "teacher/create-course",
          details: this.form,
        })
        .then((resp) => {
          this.loading = false;
          console.log(resp);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Course created successfully",
          });
          setTimeout(function () {
            window.location.reload();
          }, 2000);
        })
        .catch((error) => {
          this.loading = false;
          this.$store.commit("setLoader", false);
          this.$store.commit("setNotification", {
            type: 2,
            message: "Error creating course",
          });
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.i-vid {
  display: flex;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  align-items: center;
  padding: 8px 13px;
  border-radius: 7px;
  margin-right: 5px;
}

.i-vid svg {
  width: 20px;
}
.action svg {
  width: 25px;
}
</style>
