<template>
  <div class="flex w-full pt-8 sm:pr-5 pr-2 lg:pl-0 pl-0" v-if="loaded">
    <div class="w-full pl-6">
      <div
        class="w-full h-[79px] bg-white rounded-[26px] flex-between-center px-6 mb-6"
      >
        <div class="flex w-full">
          <!-- <div class="w-[44px] h-[44px] rounded-full mr-3 relative">
            <img
              src="../../../assets/images/s-dashboard/c-avatar4.png"
              class="w-full"
              alt=""
            />
            <div
              class="w-[9px] h-[9px] rounded-full bg-[rgba(89,214,84,1)] border-[1.9px] chat-notif"
            ></div>
          </div> -->
          <div>
            <p class="text-[rgba(4,4,4,1)] font-[MontMedium]">Admin</p>
            <p class="text-[rgba(146,149,163,1)] font-[MontMedium] text-xs">
              Online
            </p>
          </div>
        </div>
      </div>

      <div>
        <div
          class="mb-4 flex w-100"
          v-for="(item, index) in chats"
          :key="index"
          :class="item.sender_id != 0 ? 'flex-right' : ''"
        >
          <div id="user_chat">
            <div
              class="relative"
              :class="item.sender_id == 0 ? 'flex-all-start' : 'flex-all-end'"
            >
              <!-- <div
            class="w-[35px] h-[35px] bg-[rgba(196,196,196,1)] rounded-full mr-3 absolute bottom-0"
          ></div> -->
              <div
                class="w-[450px] h-auto bg-white font-[MontMedium] text-sm p-2.5 rounded-b-lg rounded-tr-lg shadow-2xl shadow-[rgba(241,224,224,0.09)] chat_wrap"
              >
                <p class="text-[rgba(4,4,4,1)] mb-5">{{ item.message }}</p>
              </div>
            </div>
            <div class="mt-2">
              <p
                class="text-[rgba(146,149,163,1)] font-[MontMedium] text-xs"
                :class="item.sender_id != 0 ? 'text-right' : ''"
              >
                {{ item.diff.replace("before", "ago") }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="relative w-full flex-all-start mb-5" style="">
        <div class="relative w-full">
          <input
            type="text"
            id="message"
            class="instructor_chat bg-[rgba(255,255,255,0.8)] border-0 text-[rgba(146,149,163,1)] outline-none text-sm rounded-lg focus:ring-[rgba(255,255,255,1)] focus:border-[rgba(255,255,255,1)] block w-full h-[45px] placeholder:text-[rgba(146,149,163,1)] placeholder:text-xs font-[MontMedium] pr-12 pl-14"
            placeholder="Type a message..."
            required
            v-model="message"
          />
          <div class="absolute left-6 top-3.5 w-[20px] h-[20px]">
            <img
              src="../../../assets/images/s-dashboard/heroicons-outline_emoji-happy.jpg"
              alt=""
            />
          </div>
          <div
            class="w-[32px] h-[32px] bg-[rgba(255,255,255,1)] cursor-pointer absolute right-2 top-1.5 rounded-lg flex justify-center items-center"
          >
            <img
              src="../../../assets/images/s-dashboard/send-a.jpg"
              @click.prevent="sendMessage()"
              class="w-[20px]"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { initFlowbite } from "flowbite";
  export default {
    name: "student-resources",
    data() {
      return {
        users: [],
        user: {},
        message: "",
        loaded: false,
        chats: [],
      };
    },
    methods: {
      getAdminChats() {
        this.$store.commit("setLoader", true);
        this.$store.dispatch("get", "get-admin-chats").then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp.data);
          this.chats = resp.data;
          this.loaded = true;
        });
      },
      sendMessage() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("post", {
            endpoint: "send-message",
            details: { message: this.message, user: 0 },
          })
          .then((resp) => {
            this.$store.commit("setLoader", false);
            console.log(resp.data);
            this.chats.push({ ...resp.data.data });
          })
          .catch(() => {
            this.$store.commit("setLoader", false);
          });
      },
    },
    mounted() {
      initFlowbite();
      this.getAdminChats();
    },
  };
</script>
<style scoped>
  #user_chat {
    width: 100%;
    max-width: 20em;
  }
  .flex-right {
    justify-content: end;
  }
</style>
