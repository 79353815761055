<template>
  <div
    class="flex md:flex-row flex-col w-full pt-8 md:pr-5 pr-2 lg:pl-0 md:pl-5 pl-2"
    v-if="loaded"
  >
    <div
      class="w-full bg-white rounded-[26px] pt-5 sm:px-6 px-2 md:mb-0 mb-5 pb-8"
    >
      <div class="mb-2">
        <p class="font-[MontMedium] text-[rgba(4,4,4,1)] text-lg mb-1">
          Profile Setting
        </p>
        <p class="text-[#9295A3] text-xs font-[MontMedium]">Personal Details</p>
      </div>
      <form action="" method="">
        <div class="w-full flex-all-center py-8">
          <div class="profile-img w-[80px] h-[80px] rounded-full relative">
            <img :src="user.profile_img" v-if="user.profile_img" alt="" />
            <svg
              v-else
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              x="0px"
              y="0px"
              viewBox="0 0 415.744 415.744"
              style="enable-background: new 0 0 415.744 415.744"
              xml:space="preserve"
            >
              <g>
                <g>
                  <path
                    d="M207.872,0c-53.76,0-97.28,43.52-97.28,97.28s43.52,97.28,97.28,97.28s97.28-43.52,97.28-97.28S261.632,0,207.872,0z"
                  />
                </g>
              </g>
              <g>
                <g>
                  <path
                    d="M245.76,205.824h-75.776c-76.288,0-138.24,61.952-138.24,138.24v56.32c0,8.704,6.656,15.36,15.36,15.36H368.64 c8.704,0,15.36-6.656,15.36-15.36v-56.32C384,267.776,322.048,205.824,245.76,205.824z"
                  />
                </g>
              </g>
            </svg>
            <label
              for="profileImage"
              class="absolute bottom-0 right-[-10px] border w-[30px] h-[30px] rounded-full profile-camera flex-all-center cursor-pointer"
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 17 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.66093 5.99341C7.2156 5.99341 5.99426 7.21474 5.99426 8.66007C5.99426 10.1054 7.2156 11.3267 8.66093 11.3267C10.1063 11.3267 11.3276 10.1054 11.3276 8.66007C11.3276 7.21474 10.1063 5.99341 8.66093 5.99341ZM8.66093 9.99341C7.95093 9.99341 7.3276 9.37007 7.3276 8.66007C7.3276 7.95007 7.95093 7.32674 8.66093 7.32674C9.37093 7.32674 9.99426 7.95007 9.99426 8.66007C9.99426 9.37007 9.37093 9.99341 8.66093 9.99341Z"
                  fill="white"
                />
                <path
                  d="M13.9943 3.99341H12.2703L10.4656 2.18874C10.4038 2.12671 10.3303 2.07752 10.2494 2.044C10.1685 2.01047 10.0818 1.99328 9.99426 1.99341H7.3276C7.24003 1.99328 7.15331 2.01047 7.07242 2.044C6.99153 2.07752 6.91807 2.12671 6.85626 2.18874L5.0516 3.99341H3.3276C2.59226 3.99341 1.99426 4.59141 1.99426 5.32674V12.6601C1.99426 13.3954 2.59226 13.9934 3.3276 13.9934H13.9943C14.7296 13.9934 15.3276 13.3954 15.3276 12.6601V5.32674C15.3276 4.59141 14.7296 3.99341 13.9943 3.99341ZM3.3276 12.6601V5.32674H5.3276C5.50493 5.32674 5.67426 5.25674 5.79893 5.13141L7.6036 3.32674H9.71826L11.5229 5.13141C11.5847 5.19344 11.6582 5.24263 11.7391 5.27615C11.82 5.30968 11.9067 5.32687 11.9943 5.32674H13.9943L13.9956 12.6601H3.3276Z"
                  fill="white"
                />
              </svg>
            </label>
            <input type="file" id="profileImage" style="visibility: hidden" />
          </div>
        </div>

        <div class="md:flex block w-full">
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="fname"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >First Name</label
            >
            <input
              type="text"
              id="fname"
              class="profile_input"
              v-model="user.firstname"
              required
            />
          </div>
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="full-name"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Last Name</label
            >
            <input
              type="text"
              id="full-name"
              class="profile_input"
              v-model="user.lastname"
              required
            />
          </div>
        </div>
        <div class="md:flex block w-full">
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="email"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Email address</label
            >
            <input
              type="text"
              id="email"
              class="profile_input"
              disabled
              v-model="user.email"
              required
            />
          </div>
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="countries"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Country</label
            >
            <select
              v-model="user.country"
              id="countries"
              class="profile_input"
              @change="setState($event.target.value)"
            >
              <option
                v-for="(item, index) in countries"
                :value="item.name"
                :key="index"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="md:flex block w-full">
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="State/Province"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >State/Province</label
            >
            <select v-model="user.state" id="states" class="profile_input">
              <option
                v-for="(item, index) in states"
                :value="item.name"
                :key="index"
              >
                {{ item.name }}
              </option>
            </select>
          </div>
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="City"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >City</label
            >
            <input
              type="text"
              id="City"
              class="profile_input"
              v-model="user.city"
            />
          </div>
        </div>

        <div class="md:flex block w-full">
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="Address"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Address</label
            >
            <input
              type="text"
              id="Address"
              class="profile_input"
              v-model="user.address"
              required
            />
          </div>
        </div>

        <div class="md:flex block w-full">
          <div class="mb-6 w-full mr-5 relative">
            <label
              for="bio"
              class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
              >Bio</label
            >
            <textarea
              v-model="user.teacher.bio"
              class="block p-2.5 w-full text-sm text-gray-900 rounded-lg border border-gray-200 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              id="bio"
              cols="30"
              rows="4"
            ></textarea>
          </div>
        </div>

        <div class="flex sm:flex-wrap flex-nowrap">
          <button
            @click.prevent="updateProfile()"
            class="fill-btn mr-5 sm:!w-[180px] !w-[140px] mb-4"
          >
            Save profile
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { initFlowbite } from "flowbite";
import country from "@/utils/country.json";
export default {
  name: "student-profile",
  data() {
    return {
      countries: [],
      states: [],
      user: {},
      loaded: false,
    };
  },
  methods: {
    getProfile() {
      this.$store.commit("setLoader", true);
      this.$store.dispatch("get", "teacher/get-profile").then((resp) => {
        this.$store.commit("setLoader", false);
        this.user = resp.data;
        console.log(this.user);
        if (this.user.country) {
          this.setState(this.user.country);
        }
        this.loaded = true;
      });
    },
    setState(value) {
      this.states = this.countries.find((item) => item.name == value)["states"];
    },
    updateProfile() {
      this.$store.commit("setLoader", true);
      this.user.bio = this.user.teacher.bio;
      var img = document.getElementById("profileImage").files[0];
      console.log(img);
      var formD = new FormData();
      if (img) {
        formD.append("image", img);
      }

      formD.append("bio", this.user.teacher.bio);
      formD.append("firstname", this.user.firstname);
      formD.append("lastname", this.user.lastname);
      formD.append("country", this.user.country);
      formD.append("address", this.user.address);
      formD.append("city", this.user.city);
      formD.append("state", this.user.state);
      this.$store
        .dispatch("post", {
          endpoint: "update-profile",
          details: formD,
        })
        .then((resp) => {
          this.$store.commit("setLoader", false);
          console.log(resp);
          var user_details = {
            user: resp.data,
            token: this.$store.state.token,
          };
          this.$store.commit("setUser", user_details);
          this.$store.commit("setNotification", {
            type: 1,
            message: "Profile updated successfully",
          });
        })
        .catch(() => {
          this.$store.commit("setLoader", false);
        });
    },
  },
  mounted() {
    this.getProfile();
    initFlowbite();
    this.countries = country;
  },
};
</script>

<style scoped>
.profile-img svg,
.profile-img img {
  border-radius: 80px;
}
</style>
