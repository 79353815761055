<template>
  <div class="pt-8 md:pr-5 pr-2 lg:pl-0 md:pl-5 pl-2 lg:mb-0 mb-6">
    <div class="flex justify-end mb-3">
      <button
        class="btn btn-primary"
        data-modal-target="addSchedule"
        data-modal-toggle="addSchedule"
      >
        Add Schedule
      </button>
    </div>
    <div class="bg-white w-full rounded-[26px] mr-6 lg:mb-0 mb-5">
      <!-- :min-date="new Date()" -->
      <vue-cal
        class="vuecal--blue-theme"
        active-view="month"
        :disable-views="['week', 'years', 'year']"
        :events="schedules"
        events-on-month-view="short"
      ></vue-cal>
    </div>
    <div
      class="lg:w-[560px] mt-3 w-full bg-white h-auto rounded-[26px] sm:px-6 px-4 pt-4"
      style="box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px"
    >
      <div class="mt-7 pb-5">
        <p class="text-[rgba(4,4,4,1)] text-lg font-[MontMedium] mb-4">
          Reminders
        </p>
        <a
          class="flex-between-center mb-6"
          v-for="(schedule, index) in schedules"
          :key="index"
          :href="schedule.url"
          target="_blank"
        >
          <div class="flex-all-start" v-if="index < 10">
            <div>
              <p class="text-[rgba(4,4,4,1)] text-sm font-[MontMedium] mb-2">
                {{ schedule.title }}
              </p>
            </div>
          </div>
          <div class="cursor-pointer text-xs">
            {{ formatDateTimeSecond(schedule.start) }}
          </div>
        </a>
      </div>
    </div>

    <div
      id="addSchedule"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full"
    >
      <div class="relative w-full max-w-2xl max-h-full">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow dark:bg-gray-700">
          <!-- Modal header -->
          <div
            class="flex items-start justify-between p-4 border-b rounded-t dark:border-gray-600"
          >
            <h3 class="text-xl font-semibold text-gray-900 dark:text-white">
              Add Schedule
            </h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ml-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-hide="addSchedule"
            >
              <svg
                class="w-3 h-3"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 14 14"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                />
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6">
            <form action="">
              <div class="mb-6">
                <label
                  for="title"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Title</label
                >
                <input
                  type="text"
                  id="title"
                  v-model="event.title"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Title"
                  required
                />
              </div>
              <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label
                    for="start"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >Start DateTime</label
                  >
                  <input
                    type="datetime-local"
                    v-model="event.start_date"
                    id="start"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="John"
                    required
                  />
                </div>
                <div>
                  <label
                    for="end"
                    class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >End DateTime</label
                  >
                  <input
                    type="datetime-local"
                    v-model="event.end_date"
                    id="end"
                    class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Doe"
                    required
                  />
                </div>
              </div>

              <div class="mb-6">
                <label
                  for="courses"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Course</label
                >
                <select
                  v-model="event.course"
                  id="courses"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="">Select Course</option>
                  <option
                    v-for="(course, index) in courses"
                    :key="index"
                    :value="course.uuid"
                  >
                    {{ course.title }}
                  </option>
                </select>
              </div>

              <div class="mb-6">
                <label
                  for="Url"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Call Url</label
                >
                <input
                  type="text"
                  id="Url"
                  v-model="event.url"
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Call url e.g Zoom, meet url"
                />
              </div>

              <div class="mb-6">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >Content</label
                >
                <textarea
                  id="message"
                  rows="4"
                  v-model="event.content"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Content"
                ></textarea>
              </div>
              <button
                type="button"
                class="btn btn-primary"
                @click.prevent="addSchedule()"
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { initFlowbite } from "flowbite";
  import VueCal from "vue-cal";
  import "vue-cal/dist/vuecal.css";
  export default {
    name: "student-schedule",
    components: {
      VueCal,
    },
    mounted() {
      initFlowbite();
    },
    data() {
      return {
        event: {
          start_date: "",
          end_date: "",
          content: "rjrjr",
          title: "Intro class",
          course: "",
          url: "",
        },
        events: [],
        // events: [
        //   {
        //     start: "2023-09-04 10:30",
        //     end: "2023-09-04 11:30",
        //     title: "Need to go shopping",
        //     content: '<i class="icon material-icons">shopping_cart</i>',
        //   },
        //   {
        //     start: "2023-09-04 14:30",
        //     end: "2023-09-04 17:30",
        //     title: "Golf with John",
        //     content: '<i class="icon material-icons">golf_course</i>',
        //   },
        //   {
        //     start: "2023-09-04 16:00",
        //     end: "2023-09-04 20:00",
        //     title: "Dad's birthday!",
        //     content: '<i class="icon material-icons">cake</i>',
        //   },
        // ],
        courses: [],
        schedules: [],
      };
    },
    methods: {
      addSchedule() {
        console.log(this.event);
        if (!this.event.start_date || this.event.start_date.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Start datetime is required",
          });
          return false;
        }
        if (!this.event.end_date || this.event.end_date.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "End datetime is required",
          });
          return false;
        }
        if (!this.event.title || this.event.title.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Title is required",
          });
          return false;
        }
        if (!this.event.course || this.event.course.length < 1) {
          this.$store.commit("setNotification", {
            type: 2,
            message: "Course is required",
          });
          return false;
        }
        console.log(this.event);
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("post", {
            endpoint: "teacher/add-schedule",
            details: this.event,
          })
          .then((resp) => {
            this.$store.commit("setLoader", false);
            console.log(resp);
            this.$store.commit("setNotification", {
              type: 1,
              message: "Course created successfully",
            });
            setTimeout(function () {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            this.$store.commit("setLoader", false);
            this.$store.commit("setNotification", {
              type: 2,
              message: "Error creating schedule",
            });
            console.log(error);
          });
      },
      getCourses() {
        this.$store
          .dispatch("get", "teacher/courses")
          .then((resp) => {
            console.log(resp);
            this.courses = resp.data;
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
      getSchedules() {
        this.$store
          .dispatch("get", "teacher/get-schedules")
          .then((resp) => {
            this.schedules = resp.data;
            this.schedules.forEach((item) => {
              item.start = item.start.replace("T", " ");
              item.end = item.end.replace("T", " ");
            });
            console.log(this.schedules);
          })

          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
    },
    created() {
      this.getCourses();
      this.getSchedules();
    },
  };
</script>

<style scoped>
  .vuecal {
    height: 33em;
  }
</style>
