import StudentLayout from "@/views/student/layout/layout.vue";
import StudentDashboard from "@/views/student/dashboard/index.vue";
import CoursesIndex from "@/views/student/courses/index.vue";
import ShowCourse from "@/views/student/courses/show.vue";
import ShowModule from "@/views/student/courses/module.vue";
import InnerLayout from "@/components/layout.vue";
import StudentChat from "@/views/student/chat/chat.vue";
import StudentProfile from "@/views/student/profile/index.vue";
import StudentSchedule from "@/views/student/schedule/index.vue";
import MyResources from "@/views/student/resources/index.vue";
import StudentSettings from "@/views/student/settings/index.vue";
import Notification from "@/views/student/dashboard/notification.vue";

const studentRoutes = [
  {
    path: "/student",
    component: StudentLayout,
    children: [
      {
        path: "dashboard",
        name: "dashboard",
        component: StudentDashboard,
        meta: {
          name: "Student Dashboard",
        },
      },
      {
        path: "/notif",
        component: Notification,
        name: "Notification",
      },

      {
        path: "courses",
        name: "courses",
        component: InnerLayout,
        children: [
          {
            path: "/",
            name: "courses",
            component: CoursesIndex,
            meta: {
              name: "courses",
            },
          },
          {
            path: ":id/show",
            name: "course",
            component: ShowCourse,
            meta: {
              name: "course",
            },
          },
          {
            path: "module/:id",
            name: "module",
            component: ShowModule,
            meta: {
              name: "module",
            },
          },
          {
            path: "resources",
            name: "resources",
            component: MyResources,
            meta: {
              name: "resources",
            },
          },
          {
            path: "chat",
            name: "chat",
            component: StudentChat,
            meta: {
              name: "chat",
            },
          },
          {
            path: "schedule",
            name: "schedule",
            component: StudentSchedule,
            meta: {
              name: "Schedule",
            },
          },
          {
            path: "profile",
            name: "profile",
            component: StudentProfile,
            meta: {
              name: "profile",
            },
          },
          {
            path: "settings",
            name: "settings",
            component: StudentSettings,
            meta: {
              name: "settings",
            },
          },
        ],
      },
    ],
  },
];

export default studentRoutes;
