<template>
  <div
    class="w-full teach-dash sm:pr-5 pr-2 lg:pl-0 sm:pl-5 pl-2"
    v-if="loaded"
  >
    <div class="grid lg:grid-cols-3 md:grid-cols-2 lg:gap-6 gap-4 mb-5">
      <router-link
        to="/teacher/student"
        class="bg-white rounded-[14px] py-6 pl-7 w-full shadow-lg shadow-[rgba(241,224,224,0.04)] flex-all-start"
      >
        <div class="mr-4 sm:w-[56px] w-[45px]">
          <img src="../../assets/images/s-dashboard/t-student.png" alt="" />
        </div>
        <div>
          <p class="text-[rgba(4,4,4,1)] text-base font-[MontBold]">
            {{ totalStudent }}
          </p>
          <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">
            Total Students
          </p>
        </div>
      </router-link>
      <router-link
        to="/teacher/courses"
        class="bg-white rounded-[14px] py-6 pl-7 w-full shadow-lg shadow-[rgba(241,224,224,0.04)] flex-all-start"
      >
        <div class="mr-4 sm:w-[56px] w-[45px]">
          <img src="../../assets/images/s-dashboard/course.png" alt="" />
        </div>
        <div>
          <p class="text-[rgba(4,4,4,1)] text-base font-[MontBold]">
            {{ courses.length }}
          </p>
          <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">
            Course
          </p>
        </div>
      </router-link>
      <div
        class="bg-white rounded-[14px] py-6 pl-7 w-full shadow-lg shadow-[rgba(241,224,224,0.04)] flex-all-start"
      >
        <div class="mr-4 sm:w-[56px] w-[45px]">
          <img src="../../assets/images/s-dashboard/t-video.png" alt="" />
        </div>
        <div>
          <p class="text-[rgba(4,4,4,1)] text-base font-[MontBold]">
            {{ totalVideo }}
          </p>
          <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">
            Total Video
          </p>
        </div>
      </div>
    </div>

    <div class="block lg:flex justify-between items-start mb-5">
      <div
        class="lg:w-[63%] w-full bg-white rounded-[18px] h-[200px] mr-5 lg:mb-0 mb-5"
      ></div>
      <div
        class="bg-white lg:w-[45%] w-full rounded-[18px] h-auto lg:px-6 px-3 pt-4 pb-8"
      >
        <h4 class="font-[MontMedium] text-lg">Top Course</h4>
        <!-- <EmptyClass /> -->
        <div v-if="courses.length > 0">
          <div
            v-for="(item, index) in courses"
            :key="index"
            class="py-3 px-4 mt-4 rounded-lg border-2 border-[rgba(243,245,248,1)] courses_wrap w-full"
          >
            <div class="flex justify-between items-center courses_content">
              <div class="sm:w-[290px] w-auto">
                <div class="flex-all-start flex-wrap">
                  <div class="mr-4 sm:w-[40px] w-[35px]">
                    <img
                      :src="item.image"
                      class="rounded-full"
                      alt="grph-design"
                    />
                  </div>
                  <div class="flex flex-col flex-wrap">
                    <p
                      class="text-[rgba(4,4,4,1)] font-[MontMedium] sm:text-base text-sm mr-2 !break-words"
                    >
                      {{ item.title }}
                    </p>
                    <p
                      class="text-[rgba(146,149,163,1)] sm:text-sm text-xs font-[MontMedium]"
                    >
                      <!-- #54687KB -->
                    </p>
                  </div>
                </div>
              </div>
              <div>
                <p
                  class="font-[MontMedium] text-[rgba(42,43,97,1)] text-sm text-right"
                >
                  free
                </p>
                <p
                  class="text-[rgba(146,149,163,1)] text-xs font-[MontMedium] text-right whitespace-nowrap"
                >
                  {{ item.students_count }} students
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
          role="alert"
          v-else
        >
          <span class="block sm:inline">No courses found</span>
        </div>
      </div>
    </div>

    <!-- <div class="block lg:flex justify-between items-start pb-8">
      <div
        class="lg:w-[63%] w-full bg-white rounded-[18px] h-[200px] mr-5 lg:mb-0 mb-5"
      ></div>
      <div
        class="bg-white lg:w-[45%] w-full rounded-[18px] h-auto lg:px-6 px-3 pt-4 pb-8"
      >
        <div class="flex justify-between items-center mb-4">
          <p class="font-[MontMedium] text-lg">Quick Chat</p>
          <span>
            <svg
              width="41"
              height="32"
              viewBox="0 0 41 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.323 17.5792L8.31441 22.4287L4.09961 18.5423V28.7999H17.193L12.2115 25.4704L18.423 20.7792L14.323 17.5792ZM23.8063 3.19995L28.7878 6.52955L22.5763 11.2208L26.6763 14.4207L32.6848 9.57115L36.8996 13.4576V3.19995H23.8063Z"
                fill="#3165E6"
              />
            </svg>
          </span>
        </div>
        <div class="flex justify-end items-start mb-5">
          <div
            class="bg-[rgba(42,43,97,1)] rounded-t-xl rounded-bl-xl lg:pl-12 pl-6 w-[70%] pb-3 pt-2 mr-4"
          >
            <p class="text-white font-[MontMedium] text-xs text-left">
              I'm ready for next project !
            </p>
          </div>
          <div class="w-[35px] h-[35px] rounded-full border">
            <img
              src="../../assets/images/s-dashboard/Avt1.png"
              class="w-full"
              alt="AV1"
            />
          </div>
        </div>
        <div class="flex justify-start items-start mb-8">
          <div class="w-[35px] h-[35px] rounded-full border mr-2">
            <img
              src="../../assets/images/s-dashboard/Avt2.png"
              class="w-full"
              alt="AV2"
            />
          </div>
          <div class="flex flex-col w-[297px]">
            <div
              class="bg-[rgba(242,245,250,1)] rounded-t-xl rounded-br-xl pl-6 w-full pb-4 pt-2 pr-3 mb-2"
            >
              <p
                class="text-[rgba(146,149,163,1)] font-[MontMedium] text-xs text-left"
              >
                It is good idea and how about next month to prepare? 😔
              </p>
            </div>
            <div
              class="bg-[rgba(242,245,250,1)] rounded-t-xl rounded-br-xl pl-6 w-[65%] pb-2 pt-2 mr-4"
            >
              <p
                class="text-[rgba(146,149,163,1)] font-[MontMedium] text-xs text-left"
              >
                Are your free at 2 pm?
              </p>
            </div>
          </div>
          <div class="pt-4">
            <svg
              width="27"
              height="15"
              viewBox="0 0 27 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.7831 4.91772C14.6843 4.91772 15.4148 4.52597 15.4148 4.04272C15.4148 3.55948 14.6843 3.16772 13.7831 3.16772C12.8819 3.16772 12.1514 3.55948 12.1514 4.04272C12.1514 4.52597 12.8819 4.91772 13.7831 4.91772Z"
                fill="#040404"
              />
              <path
                d="M13.7831 8.41772C14.6843 8.41772 15.4148 8.02597 15.4148 7.54272C15.4148 7.05948 14.6843 6.66772 13.7831 6.66772C12.8819 6.66772 12.1514 7.05948 12.1514 7.54272C12.1514 8.02597 12.8819 8.41772 13.7831 8.41772Z"
                fill="#040404"
              />
              <path
                d="M13.7831 11.9177C14.6843 11.9177 15.4148 11.526 15.4148 11.0427C15.4148 10.5595 14.6843 10.1677 13.7831 10.1677C12.8819 10.1677 12.1514 10.5595 12.1514 11.0427C12.1514 11.526 12.8819 11.9177 13.7831 11.9177Z"
                fill="#040404"
              />
            </svg>
          </div>
        </div>
        <form>
          <div class="flex-all-start w-full">
            <div class="w-full relative">
              <input
                type="Message"
                id="message"
                class="gray-input"
                placeholder="Type a Message"
                required
              />
              <span class="absolute right-8 top-2.5">
                <svg
                  width="19"
                  height="16"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M9.09668 0L9.06367 15.4284" stroke="#9295A3" />
                  <path
                    d="M18.1162 7.99976L0.0122929 7.99976"
                    stroke="#9295A3"
                  />
                </svg>
              </span>
            </div>
            <div class="ml-4">
              <button>
                <svg
                  width="42"
                  height="23"
                  viewBox="0 0 42 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M33.7933 7.23835L17.729 21.863L14.9583 13.0696L2.14234 7.38626L33.7933 7.23835Z"
                    stroke="#2A2B61"
                    stroke-width="2"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div></div>
    </div> -->
  </div>
</template>
<script>
  export default {
    name: "teacher-dashboard",
    data() {
      return {
        courses: [],
        loaded: false,
        totalVideo: 0,
        totalStudent: 0,
      };
    },
    methods: {
      getCourses() {
        this.$store
          .dispatch("get", "teacher/courses")
          .then((resp) => {
            console.log(resp);
            this.loaded = true;
            this.courses = resp.data;
            this.courses.forEach((item) => {
              this.totalVideo += this.calculateTotalVideos(item);
              this.totalStudent += item.students_count;
            });
          })
          .catch((error) => {
            this.$store.dispatch("handleError", error);
          });
      },
      calculateTotalVideos(course) {
        var total = 0;
        course.modules.forEach((item) => {
          total += item.videos.length;
        });
        return total;
      },
    },
    created() {
      this.getCourses();
    },
  };
</script>
<style scoped>
  .teach-dash {
    padding-top: 30px;
  }
</style>
