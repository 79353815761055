<template>
  <div
    class="flex md:flex-row flex-col w-full pt-8 md:pr-5 pr-2 lg:pl-0 md:pl-5 pl-2"
  >
    <div class="w-full bg-white rounded-[26px] pt-5 px-6 md:mb-0 mb-5">
      <div class="mb-2">
        <p class="font-[MontMedium] text-[rgba(4,4,4,1)] text-lg">
          Profile Setting
        </p>
      </div>

      <div class="mb-4 w-full overflow-scroll">
        <ul
          class="flex -mb-px text-sm font-medium text-center md:w-full w-[516px] overflow-x-auto"
          id="myTab"
          data-tabs-toggle="#myTabContent"
          role="tablist"
        ></ul>
      </div>
      <div id="myTabContent">
        <div
          class=""
          id="personal"
          role="tabpanel"
          aria-labelledby="personal-tab"
        >
          <form>
            <div class="md:flex block w-full">
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="fname"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >First Name</label
                >
                <input
                  type="text"
                  id="fname"
                  class="profile_input"
                  v-model="user.firstname"
                  required
                />
              </div>
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="full-name"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Last Name</label
                >
                <input
                  type="text"
                  id="full-name"
                  class="profile_input"
                  v-model="user.lastname"
                  required
                />
              </div>
            </div>
            <div class="md:flex block w-full">
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="email"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Email address</label
                >
                <input
                  type="text"
                  id="email"
                  class="profile_input"
                  disabled
                  v-model="user.email"
                  required
                />
              </div>
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="countries"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Country</label
                >
                <select
                  v-model="user.country"
                  id="countries"
                  class="profile_input"
                  @change="setState($event.target.value)"
                >
                  <option
                    v-for="(item, index) in countries"
                    :value="item.name"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="md:flex block w-full">
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="State/Province"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >State/Province</label
                >
                <select v-model="user.state" id="states" class="profile_input">
                  <option
                    v-for="(item, index) in states"
                    :value="item.name"
                    :key="index"
                  >
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="City"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >City</label
                >
                <input
                  type="text"
                  id="City"
                  class="profile_input"
                  v-model="user.city"
                />
              </div>
            </div>

            <div class="md:flex block w-full">
              <div class="mb-6 w-full mr-5 relative">
                <label
                  for="Address"
                  class="block mb-2 text-xs absolute top-2.5 left-5 font-[MontMedium] text-[rgba(146,149,163,1)]"
                  >Address</label
                >
                <input
                  type="text"
                  id="Address"
                  class="profile_input"
                  v-model="user.address"
                  required
                />
              </div>
            </div>

            <div class="flex sm:flex-wrap flex-nowrap">
              <button
                @click.prevent="updateProfile()"
                class="fill-btn mr-5 sm:!w-[180px] !w-[140px] mb-4"
              >
                Save profile
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { initFlowbite } from "flowbite";
import country from "@/utils/country.json";
export default {
  name: "student-profile",
  data() {
    return {
      countries: [],
      states: [],
      user: {},
    };
  },
  methods: {
    getProfile() {
      this.$store.commit("setLoader", true);
      this.$store.dispatch("get", "student/get-profile").then((resp) => {
        this.$store.commit("setLoader", false);
        this.user = resp.data;
        if (this.user.country) {
          this.setState(this.user.country);
        }
      });
    },
    setState(value) {
      this.states = this.countries.find((item) => item.name == value)["states"];
    },
    updateProfile() {
      this.$store.commit("setLoader", true);
      this.$store
        .dispatch("post", {
          endpoint: "update-profile",
          details: this.user,
        })
        .then(() => {
          this.$store.commit("setLoader", false);

          this.$store.commit("setNotification", {
            type: 1,
            message: "Profile updated successfully",
          });
        })
        .catch(() => {
          this.$store.commit("setLoader", false);
        });
    },
  },
  mounted() {
    this.getProfile();
    initFlowbite();
    this.countries = country;
  },
};
</script>
