<template>
  <div>
    <ILoader v-if="loader" />
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ILoader from "@/components/loader.vue";
export default {
  name: "App",
  components: { ILoader },
  computed: {
    ...mapState({
      loader: (context) => context.loader,
    }),
  },
  created() {
    this.$store.commit("getUser");
    // var token = localStorage.getItem("token");
    // var userr = localStorage.getItem("user");
    // if (token && userr) {
    //   this.$store.state.token = token;
    //   var user = decodeURIComponent(userr);
    //   this.$store.state.user = JSON.parse(user);
    // } else {
    //   this.$router.push("/");
    // }
    // if (
    //   this.$route.meta.AuthRequired ||
    //   this.$route.matched.find((d) => d.meta.AuthRequired)
    // ) {
    //   if (!userr) {
    //     this.$router.push("/");
    //     return false;
    //   }
    // }
  },
};
</script>

<style></style>
