<template>
   
        <div class="w-full pt-8 sm:pr-5 pr-2 lg:pl-0 md:pl-5 sm:pl-5 pl-2  pb-5">
            <div class="w-full bg-white rounded-[26px] h-auto px-5 pt-5">

                <div class="flex-between-center">
                    <p class="text-[rgba(4,4,4,1)] md:text-lg text-base font-[MontMedium]">
                        My Resources
                    </p>


                    <div class="md:w-[187px] w-[166px]">
                        <button class="fill-btn text-white flex-all-center text-base btn_category">
                            <span class="md:text-2xl text-lg mr-2">+</span> Add New category
                        </button>
                    </div>
                    
                </div>
        
                <div class="mb-4 border-b border-b-[rgba(248,248,250,1)] w-full md:overflow-hidden  overflow-x-scroll sites_wrap">
                    <ul class="flex flex-wrap -mb-px text-sm font-medium text-center md:w-auto w-[700px]" id="myTab"
                        data-tabs-toggle="#myTabContent" role="tablist">
                        <li class="md:mr-8 mr-0 pt-4" role="presentation">
                            <button
                                class="inline-block  px-5 pb-3 border-b-2 text-[rgba(146,149,163,1)] text-sm font-[MontMedium] resources_tab"
                                id="Blogsite-tab" data-tabs-target="#Blogsite" type="button" role="tab"
                                aria-controls="Blogsite" aria-selected="false">Blog site</button>
                        </li>
                        <li class="md:mr-8 mr-0 pt-4" role="presentation">
                            <button
                                class="inline-block px-5 pb-3 border-b-2  text-[rgba(146,149,163,1)] text-sm font-[MontMedium] resources_tab"
                                id="IllustrationSite-tab" data-tabs-target="#IllustrationSite" type="button" role="tab"
                                aria-controls="IllustrationSite" aria-selected="false">Illustration site</button>
                        </li>
                        <li class="md:mr-8 mr-0 pt-4" role="presentation">
                            <button
                                class="inline-block px-5 pb-3 border-b-2  text-[rgba(146,149,163,1)] text-sm font-[MontMedium] resources_tab"
                                id="Typography-tab" data-tabs-target="#Typography" type="button" role="tab"
                                aria-controls="Typography" aria-selected="false">Typography site</button>
                        </li>
                        <li class="md:mr-8 mr-0 pt-4" role="presentation">
                            <button
                                class="inline-block px-5 pb-3 border-b-2  text-[rgba(146,149,163,1)] text-sm font-[MontMedium] resources_tab"
                                id="Iconsite-tab" data-tabs-target="#Iconsite" type="button" role="tab"
                                aria-controls="Iconsite" aria-selected="false">Icon site</button>
                        </li>
                        <li class="pt-4" role="presentation">
                            <button
                                class="inline-block px-5 pb-3 border-b-2  text-[rgba(146,149,163,1)] text-sm font-[MontMedium] resources_tab"
                                id="imagesite-tab" data-tabs-target="#imagesite" type="button" role="tab"
                                aria-controls="imagesite" aria-selected="false">Image site</button>
                        </li>
                    </ul>
                </div>

                <div id="myTabContent">
                    <div class="hidden" id="Blogsite" role="tabpanel" aria-labelledby="Blogsite-tab">
                        <div class="flex flex-between-center pl-6">
                            <p class="text-[rgba(4,4,4,1)] font-[MontMedium] text-base">8 Blog site here</p>
                            <div class="flex">
                                <span class="mr-5 cursor-pointer">
                                    <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clip-path="url(#clip0_1646_26009)">
                                            <path
                                                d="M13.4 10.6C15.4 12.6 15.4 15.7 13.4 17.7L10.6 20.5C8.6 22.5 5.5 22.5 3.5 20.5C1.5 18.5 1.5 15.4 3.5 13.4L6 11"
                                                stroke="#040404" stroke-width="2" stroke-miterlimit="10"
                                                stroke-linecap="square" />
                                            <path
                                                d="M10.6001 13.4C8.6001 11.4 8.6001 8.3 10.6001 6.3L13.4001 3.5C15.4001 1.5 18.5001 1.5 20.5001 3.5C22.5001 5.5 22.5001 8.6 20.5001 10.6L18.0001 13"
                                                stroke="#040404" stroke-width="2" stroke-miterlimit="10"
                                                stroke-linecap="square" />
                                            <path d="M21 15H19V18H16V20H19V23H21V20H24V18H21V15Z" fill="#9295A3" />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_1646_26009">
                                                <rect width="24" height="24" fill="white" />
                                            </clipPath>
                                        </defs>
                                    </svg>

                                </span>
                                <span class="cursor-pointer">
                                    <svg width="25" height="25" viewBox="0 0 32 32" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M4 19C5.65685 19 7 17.6569 7 16C7 14.3431 5.65685 13 4 13C2.34315 13 1 14.3431 1 16C1 17.6569 2.34315 19 4 19Z"
                                            fill="#040404" />
                                        <path
                                            d="M16 19C17.6569 19 19 17.6569 19 16C19 14.3431 17.6569 13 16 13C14.3431 13 13 14.3431 13 16C13 17.6569 14.3431 19 16 19Z"
                                            fill="#040404" />
                                        <path
                                            d="M28 19C29.6569 19 31 17.6569 31 16C31 14.3431 29.6569 13 28 13C26.3431 13 25 14.3431 25 16C25 17.6569 26.3431 19 28 19Z"
                                            fill="#040404" />
                                    </svg>

                                </span>
                            </div>
                        </div>
                        <div class="mt-5">
                            <ul class="pb-20">
                                <li class="mb-5">
                                    <div
                                        class="w-full bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/medium_logo.png"
                                                    alt="medium_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">medium.com</p>
                                        </div>
                                        <button class="edit_btn ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>
                                <li class="mb-5">
                                    <div
                                        class="w-full bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/nngroup_logo.png"
                                                    alt="nngroup_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">nngroup.com</p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>
                                <li class="mb-5">
                                    <div
                                        class="w-full  bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/uxdesign_logo.png"
                                                    alt="uxdesign_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">uxdesign.cc</p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>
                                <li class="mb-5">
                                    <div
                                        class="w-full  bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/uxpin_logo.png"
                                                    alt="uxpin_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">uxpin.com</p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>

                                <li class="mb-5">
                                    <div
                                        class="w-full  bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/toptal_logo.png"
                                                    alt="toptal_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">toptal.com</p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>

                                <li class="mb-5">
                                    <div
                                        class="w-full  bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/uxmag_logo.png"
                                                    alt="uxmag_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">uxmag.com</p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>
                                <li class="mb-5">
                                    <div
                                        class="w-full  bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/uxbooth_logo.png"
                                                    alt="uxbooth_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">uxbooth.com</p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>
                                <li class="mb-5">
                                    <div
                                        class="w-full  bg-white hover:bg-[rgba(249,249,249,1)] cursor-pointer md:h-[54px] h-[48px] flex-between-center rounded-lg px-5 blog_wrap ease-in-out duration-200">
                                        <div class="flex-all-start">
                                            <span class="mr-3">
                                                <img src="../../../assets/images/s-dashboard/prototype_logo.png"
                                                    alt="prototype_logo" />
                                            </span>
                                            <p class="text-[rgba(146,149,163,1)] text-sm font-[MontMedium]">prototyper.io
                                            </p>
                                        </div>
                                        <button class="edit_btn hidden ease-in-out duration-200">
                                            <svg width="21" height="21" viewBox="0 0 24 24" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 22H2V17L17 2L22 7L7 22Z" stroke="#9295A3" stroke-width="2"
                                                    stroke-miterlimit="10" stroke-linecap="square" />
                                            </svg>

                                        </button>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="hidden p-5" id="IllustrationSite" role="tabpanel" aria-labelledby="IllustrationSite-tab">
                        <p>Illustration site</p>
                    </div>
                    <div class="hidden p-4" id="Typography" role="tabpanel" aria-labelledby="Typography-tab">
                        <p>Typography</p>
                    </div>
                    <div class="hidden p-4" id="Iconsite" role="tabpanel" aria-labelledby="Iconsite-tab">
                        <p>Iconsite</p>
                </div>
                <div class="hidden p-4" id="imagesite" role="tabpanel" aria-labelledby="imagesite-tab">
                    <p>imagesite</p>
                </div>
            </div>
        </div>
    
</div></template>
<script>
import { initFlowbite } from "flowbite";
export default {
    name: 'my-resources',
    mounted() {
        initFlowbite();
    }
}
</script>