<template>
  <div class="w-full pt-8 sm:pr-5 pr-2 lg:pl-0 md:pl-5 sm:pl-5 pl-2 pb-5">
    <!-- <div class="lg:flex block">
      <div
        class="w-full bg-white h-[300px] mr-5 rounded-2xl xl:mb-0 mb-4"
      ></div>
      <div class="lg:w-[700px] w-full bg-white h-[300px] rounded-2xl"></div>
    </div> -->

    <div class="mt-5">
      <div
        class="w-full h-auto bg-white sm:rounded-3xl pt-4 px-5 overflow-auto"
      >
        <div class="sm:flex block justify-between items-center my-2 ">
          <div class="flex-all-start">
            <p class="text-[#040404] font-[MontMedium] mr-5 whitespace-nowrap">
              Student List
            </p>
          </div>
          <div class="relative sm:w-auto w-full sm:mt-0 mt-3">
            <input
              type="text"
              id="search"
              class="bg-white border border-[#9295A3] text-sm rounded-lg focus:ring-[#9295A3] focus:border-[#9295A3] block py-2.5 pl-12 placeholder:text-[#9295A3] placeholder:text-xs font-[MontMedium] h-[36px] sm:w-[180px] w-full"
              placeholder="Search..."
              required
            />
            <div class="absolute top-2 left-5">
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.7669 20.7552C16.7311 20.7552 20.7554 16.7309 20.7554 11.7666C20.7554 6.80239 16.7311 2.77808 11.7669 2.77808C6.80264 2.77808 2.77832 6.80239 2.77832 11.7666C2.77832 16.7309 6.80264 20.7552 11.7669 20.7552Z"
                  stroke="#2A2B61"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  opacity="0.4"
                  d="M18.0186 18.4851L21.5426 22"
                  stroke="#2A2B61"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="relative overflow-x-auto w-full table_scrollbar">
          <table
            class="w-full text-sm text-left text-[rgba(4,4,4,1)] font-[MontMedium] overflow-x-auto"
          >
            <thead
              class="text-xs text-[rgba(4,4,4,1)] bg-white !font-[MontMedium]"
            >
              <tr>
                <th scope="col" class="px-6 py-3 capitalize whitespace-nowrap">
                  <div class="flex items-center whitespace-nowrap">Student</div>
                </th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center whitespace-nowrap">
                    Course Name
                  </div>
                </th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center whitespace-nowrap">
                    Enrolled Date
                  </div>
                </th>
                <th scope="col" class="px-6 py-3">
                  <div class="flex items-center whitespace-nowrap">Status</div>
                </th>
              </tr>
            </thead>
            <tbody v-if="students.length > 0">
              <tr
                class="bg-white border border-[rgba(243,245,248,1)] !rounded-xl"
                v-for="(item, index) in students"
                :key="index"
              >
                <th
                  scope="row"
                  class="px-6 !py-6 font-medium text-gray-900 whitespace-nowrap flex-all-start"
                >
                  <!-- <div
                    class="w-[30px] h-[30px] bg-[#C4C4C4] rounded-full mr-3"
                  ></div> -->
                  <p class="text-[rgba(4,4,4,1)]">
                    {{ item.student.firstname }} {{ item.student.lastname }}
                  </p>
                </th>
                <td class="px-6 !py-6 text-[#040404] whitespace-nowrap">
                  {{ item.course }}
                </td>
                <td class="px-6 !py-6 text-[#040404] whitespace-nowrap">
                  {{ formatDateTime(item.enrolled_at) }}
                </td>
                <td>
                  <div
                    class="w-[110px] h-[40px] bg-[#9BA1FF26] rounded-md flex-all-center"
                  >
                    <p class="text-[#4DC591] whitespace-nowrap">Running</p>
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td colspan="4">
                  <div
                    class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
                    role="alert"
                  >
                    <span class="block sm:inline">No student</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="flex w-full flex-all-center mt-8 pb-3">
            <ul class="flex">
              <li>
                <div
                  class="w-[35px] h-[35px] border rounded-full flex-all-center shadow-xl shadow-[rgba(241,224,224,0.06)] mr-3 hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  <i class="fa-solid fa-less-than text-sm"></i>
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  1
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] bg-[rgba(77,197,145,1)] text-white border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  2
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  3
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] border text-sm rounded-full mr-2 font-[MontMedium] flex-all-center pagination_btn hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  4
                </div>
              </li>
              <li>
                <div
                  class="w-[35px] h-[35px] text-sm border rounded-full flex-all-center shadow-xl shadow-[rgba(241,224,224,0.06)] mr-3 hover:bg-[rgba(77,197,145,1)] hover:text-white cursor-pointer"
                >
                  <i class="fa-solid fa-greater-than text-sm"></i>
                </div>
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import { initFlowbite } from "flowbite";
export default {
  name: "Students",
  data() {
    return {
      students: [],
    };
  },
  methods: {
    getStudents() {
      this.$store
        .dispatch("get", "teacher/get-students")
        .then((resp) => {
          console.log(resp.data);
          this.students = resp.data;
        })
        .catch((err) => {
          this.$store.dispatch("handleError", err);
        });
    },
  },
  created() {
    this.getStudents();
  },
  mounted() {
    initFlowbite();
  },
};
</script>
