<template>
 <div class="wrapper" style="height:100vh; width:100%">
  <div
    class="py-4 text-gray-900 dark:text-gray-200 h-full"
  >
    <div class="p-3 flex justify-between border-b item-center">
      <h2 class="text-xl">Notification</h2>
      <div class="notif-icon">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9026 8.85107L13.4593 12.4641C12.6198 13.1301 11.4387 13.1301 10.5992 12.4641L6.11841 8.85107"
            stroke="#000D35"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z"
            stroke="#000D35"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
    <div class="p-3 border-b h-[120px] w-full flex items-center">
      <img src="@/assets/images/avatar.png" alt="" class="w-10 h-10 mr-2" />
      <div class="notif-text ml-3">
        <p>New Message!</p>
        <p>3 courses has been added</p>
        <span class="text-gray-400">2hrs ago.</span>
      </div>
    </div>
    <div class="p-3 border-b flex items-center h-[120px] w-full">
      <img src="@/assets/images/avatar.png" alt="" class="w-10 h-10 mr-2" />

      <div class="notif-text ml-3">
        <p class="text-l">Congratulation Transfromed!</p>
        <p>You have just enrolled for the software development course.</p>
        <span class="text-gray-400">1hr ago.</span>
      </div>
    </div>

    <div class="p-3 border-b flex items-center h-[120px] w-full">
      <img src="@/assets/images/google.png" alt="" class="w-10 h-10 mr-2" />

      <div class="notif-text ml-3">
        <p class="text-l">Google Alert!</p>
        <p>Your password has been changed.</p>
        <span class="text-gray-400">30mins ago.</span>
      </div>
    </div>

    <div
      class="notif-footer text-center text-blue-400 p-3 border-t"
      style="position: absolute; bottom: 5px; left: 0; right: 0"
    >
      Back
  </div>
  </div>
 </div>
</template>

<script>
export default {
  name: "notification-page"
}
</script>