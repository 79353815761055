import Vue from "vue";
import VueRouter from "vue-router";
// import dashboardRoutes from "./dashboard";
import publicRoutes from "./public";
// import Layout from "../views/dashboard/layout/layout.vue";
import PublicLayout from "@/views/auth/layout/index.vue";

import studentRoutes from "./student";
import studentIndex from "@/views/student/layout/index.vue";

import teacherRoutes from "./teacher";
import TeacherIndex from "@/views/teacher/layout/index.vue";

const routes = [
  {
    path: "",
    component: PublicLayout,
    children: publicRoutes,
  },

  {
    path: "",
    component: studentIndex,
    children: studentRoutes,
    // meta: {
    //   AuthRequired: true,
    // },
  },

  {
    path: "",
    component: TeacherIndex,
    children: teacherRoutes,
    // meta: {
    //   AuthRequired: true,
    // },
  },

  // {
  //   path: "",
  //   component: Layout,
  //   children: dashboardRoutes,
  //   meta: {
  //     AuthRequired: true,
  //   },
  // },
  // {
  //   // the 404 route, when none of the above matches
  //   path: "/404",
  //   name: "404",
  //   component: () => import("@/views/public/error404.vue"),
  // },
  // {
  //   path: "/:pathMatch(.*)*",
  //   redirect: "/404",
  // },
];
Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  // base: process.env.BASE_URL,
  routes,
});

export default router;
