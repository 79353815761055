<template>
  <div>
    <button
      id="notificationDdBtn"
      data-dropdown-toggle="notificationDd"
      class="text-white font-[MontMedium] focus:ring-0 focus:outline-none focus:ring-transparent font-medium text-sm px-5 py-2.5 text-center inline-flex items-center"
      type="button"
    >
      <div class="mr-1 w-[28px]">
        <img src="@/assets/images/s-dashboard/Notification.png" alt="notif" />
      </div>
    </button>
    <!-- Dropdown menu -->
    <div
      id="notificationDd"
      class="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700"
      style="width: 380px"
    >
      <div
        class="py-4 text-gray-900 dark:text-gray-200"
        aria-labelledby="notificationDd"
      >
        <div class="p-3 flex justify-between border-b item-center">
          <h2 class="text-xl">Notification</h2>
          <div class="notif-icon">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.9026 8.85107L13.4593 12.4641C12.6198 13.1301 11.4387 13.1301 10.5992 12.4641L6.11841 8.85107"
                stroke="#000D35"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M16.9089 21C19.9502 21.0084 22 18.5095 22 15.4384V8.57001C22 5.49883 19.9502 3 16.9089 3H7.09114C4.04979 3 2 5.49883 2 8.57001V15.4384C2 18.5095 4.04979 21.0084 7.09114 21H16.9089Z"
                stroke="#000D35"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div
          class="p-3 border-b h-[120px] w-full flex items-center"
          v-for="(item, index) in activites"
          :key="index"
        >
          <div class="notif-text ml-3">
            <p>{{ item.subject }}</p>
            <p>{{ item.body }}</p>
            <div class="flex justify-between">
              <span class="text-gray-400">{{
                formatDateTime(item.created_at)
              }}</span>

              <span class="text-gray-400">
                {{ item.diff.replace("before", "ago") }}</span
              >
            </div>
          </div>
        </div>

        <p
          v-if="activites.length < 1"
          class="bg-teal-100 border border-teal-400 text-teal-700 px-4 py-3 rounded relative"
          role="alert"
        >
          No activites
        </p>

        <!-- <router-link
          class="notif-footer text-center text-blue-400 border-t"
          style="position: absolute; bottom: 5px; left: 0; right: 0"
          :to="{ name: 'Notification' }"
        >
          View all notifications
        </router-link> -->
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "activities-tab",
    data() {
      return {
        activites: [],
      };
    },
    methods: {
      getActivities() {
        this.$store.dispatch("get", "activities").then((resp) => {
          console.log(resp);
          this.activites = resp.data.data;
        });
      },
    },
    created() {
      this.getActivities();
    },
  };
</script>
