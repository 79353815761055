<template>
  <div class="flex justify-between" v-if="loaded">
    <div class="bg-white rounded-[23px] w-[70%] mt-3">
      <div class="p-4">
        <div class="img" style="width: 100%; height: 300px">
          <img
            :src="course.image"
            class="w-full h-full"
            style="object-fit: cover"
          />
        </div>

        <p class="capitalize mb-2 text-[22px] mt-2">
          {{ course.title }}
        </p>
        <div class="flex capitalize">
          <span class="border-0 border-r pr-2 text-xs m-0 text-[#9295A3]"
            >{{ course.teacher.firstname }} {{ course.teacher.lastname }}</span
          >
        </div>
      </div>
      <div class="relative">
        <div
          class="absolute -top-[28px] right-[19px]"
          v-if="!course.check_student"
        >
          <button @click.prevent="joinCourse()" class="lte-btn">
            Join Course
          </button>
        </div>
        <div class="mb-4 border-b border-gray-200 dark:border-gray-700">
          <ul
            class="flex flex-wrap -mb-px text-sm font-medium text-left show-course-tab"
            id="myTab2"
            data-tabs-toggle="#myTabContent2"
            role="tablist"
          >
            <li class="mr-2" role="presentation">
              <button
                class="inline-block p-4 border-b-2 rounded-t-lg text-[#9295A3] font-normal text-[13px] show-course-tab"
                id="overview-tab"
                data-tabs-target="#overview"
                type="button"
                role="tab"
                aria-controls="overview"
                aria-selected="false"
              >
                Description
              </button>
            </li>
          </ul>
        </div>
        <div id="myTabContent2">
          <div
            class="p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="overview"
            role="tabpanel"
            aria-labelledby="overview-tab"
          >
            <p class="text-sm text-gray-500 dark:text-gray-400">
              {{ course.description }}
            </p>
          </div>
          <div
            class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="faq"
            role="tabpanel"
            aria-labelledby="faq-tab"
          >
            <p class="text-sm text-gray-500 dark:text-gray-400">
              This is some placeholder content the
              <strong class="font-medium text-gray-800 dark:text-white"
                >faq tab's associated content</strong
              >. Clicking another tab will toggle the visibility of this one for
              the next. The tab JavaScript swaps classes to control the content
              visibility and styling.
            </p>
          </div>
          <div
            class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="discussion"
            role="tabpanel"
            aria-labelledby="discussion-tab"
          >
            <p class="text-sm text-gray-500 dark:text-gray-400">
              This is some placeholder content the
              <strong class="font-medium text-gray-800 dark:text-white"
                >discussion tab's associated content</strong
              >. Clicking another tab will toggle the visibility of this one for
              the next. The tab JavaScript swaps classes to control the content
              visibility and styling.
            </p>
          </div>
          <div
            class="hidden p-4 rounded-lg bg-gray-50 dark:bg-gray-800"
            id="reviews"
            role="tabpanel"
            aria-labelledby="reviews-tab"
          >
            <p class="text-sm text-gray-500 dark:text-gray-400">
              This is some placeholder content the
              <strong class="font-medium text-gray-800 dark:text-white"
                >reviews tab's associated content</strong
              >. Clicking another tab will toggle the visibility of this one for
              the next. The tab JavaScript swaps classes to control the content
              visibility and styling.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white rounded-[23px] p-2 w-[29%] ml-3 mt-3 p-4">
      <p class="text-lg mb-2">Course Content</p>
      <p class="text-xs text-[#9295A3] mb-2">
        Videos ({{ calculateTotalVideos(course) }})
      </p>

      <div
        id="accordion-flush"
        data-accordion="collapse"
        data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
        data-inactive-classes="text-gray-500 dark:text-gray-400"
      >
        <h2
          id="accordion-flush-heading-1"
          v-for="(item, index) in course.modules"
          :key="index"
        >
          <router-link
            :to="`/student/courses/module/${item.uuid}`"
            class="flex items-start justify-between w-full py-5 font-medium text-left text-gray-500 border-0"
          >
            <div>
              <span class="block text-[13px]">Module {{ ++index }}</span>
              <span class="text-[12px]">{{ item.title }}</span>
            </div>
            <div class="flex items-center">
              <div class="flex text-xs text-[#9295A3]">
                <svg
                  width="15"
                  height="13"
                  viewBox="0 0 15 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.75 1C8.95513 1 7.5 1.75731 7.5 2.69231C7.5 1.75731 6.04487 1 4.25 1C2.45513 1 1 1.75731 1 2.69231V12C1 11.065 2.45513 10.3077 4.25 10.3077C6.04487 10.3077 7.5 11.065 7.5 12C7.5 11.065 8.95513 10.3077 10.75 10.3077C12.5449 10.3077 14 11.065 14 12V2.69231C14 1.75731 12.5449 1 10.75 1Z"
                    stroke="#6AC9E7"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span class="ml-2">{{ item.videos.length }} Video</span>
              </div>
              <span class="ml-2">{{ item.documents.length }} document</span>
            </div>
          </router-link>
          <!-- <ul>
            <li v-for="(item2, index2) in item.videos" :key="index2">
              <vue-plyr :options="options">
                <video
                  controls
                  crossorigin
                  playsinline
                  :data-poster="item.image"
                >
                  <source size="576" :src="item2.url" type="video/mp4" />
                </video>
              </vue-plyr>
            </li>
          </ul>
          <span>Documents</span>
          <ul>
            <li v-for="(item2, index2) in item.documents" :key="index2">
              <a :href="item2.url">
                <embed :src="item2.url" width="100px" height="50px" />
              </a>
            </li>
          </ul> -->
        </h2>
      </div>
    </div>
  </div>
</template>
<script>
  import { initFlowbite } from "flowbite";
  export default {
    name: "show-course",
    data() {
      return {
        course: {},
        loaded: false,
        options: { quality: { default: "576p" } },
      };
    },
    methods: {
      getCourse() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("get", `student/course/${this.$route.params.id}`)
          .then((resp) => {
            this.$store.commit("setLoader", false);
            console.log(resp);
            this.course = resp.data;
            this.loaded = true;
          })
          .catch((err) => {
            this.$store.commit("setLoader", false);
            this.$store.dispatch("handleError", err);
          });
      },
      calculateTotalVideos(course) {
        var total = 0;
        course.modules.forEach((item) => {
          total += item.videos.length;
        });
        return total;
      },
      joinCourse() {
        this.$store.commit("setLoader", true);
        this.$store
          .dispatch("post", {
            endpoint: `student/join-course`,
            details: { course: this.$route.params.id },
          })
          .then((resp) => {
            this.$store.commit("setLoader", false);
            this.$store.commit("setNotification", {
              type: 1,
              message: "Course added successfully",
            });

            setTimeout(function () {
              window.location.reload();
            }, 1500);
            console.log(resp);
          })
          .catch((err) => {
            this.$store.commit("setLoader", false);
            this.$store.dispatch("handleError", err);
          });
      },
    },
    mounted() {
      initFlowbite();
    },
    created() {
      this.getCourse();
    },
  };
</script>
<style scoped>
  .show-course-tab[aria-selected="true"] {
    border-bottom: 2px solid #2a2b61;
    color: #2a2b61;
  }
</style>
