<template>
  <div>
    <GradientBg />
    <notification />
    <div class="h-full relative z-2">
      <Sidebar />
      <Navbar />
      <div class="lg:ml-[309px] ml-0 mt-[60px]">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "@/components/teacher-dashboard/sidebar.vue";
import Navbar from "@/components/teacher-dashboard/navbar.vue";
import GradientBg from "@/components/gradient-bg.vue";
import notification from "@/components/notification.vue";
export default {
  name: "teacher-layout",
  components: {
    Navbar,
    Sidebar,
    GradientBg,
    notification,
  },
};
</script>
